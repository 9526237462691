<div class="jumbotron">
    <div class="display-4">
      <h1 class="display-4">ANMELDUNG</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <img src="{{event.imageLink}}" alt="">
  </div>
  
  <div class="text-center">
    <div class="btn-group" role="group">
    <button type="button" class="btn btn-light" [class.active]="switch == 'tns'" id="tns" (click)="updateSwitch($event)">Teilnehmende</button>
    <button type="button" class="btn btn-light" [class.active]="switch == 'leader'" id="leader" (click)="updateSwitch($event)">Leitende</button>
    <button type="button" class="btn btn-light" [class.active]="switch == 'helfende'" id="helfende" (click)="updateSwitch($event)">Helfende</button>
  </div>
  </div>
  <div class="container">
    <form *ngIf="switch=='tns' || switch=='leader' || switch=='helfende'">
      <div class="form-group">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </form>
    <div *ngIf="switch=='tns'">
      Gesamt Teilnehmende: <b>{{totalofTns}}</b>
      <a [routerLink]="" (click)="export('tns')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
      </div>
    <table *ngIf="switch=='tns'" class="table table-bordered table-striped">
  
      <thead>
        <tr>
            <td>Nachname</td>
            <td>Vorname</td>
            <td>Stamm</td>
            <td>Abgeschlossen</td>
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let tn of tns | async | filter:{benutzer: filter, bezirk: filter, stamm: filter}">
            <td>{{ tn.nachname }}</td>
            <td>{{ tn.vorname }}</td>
            <td>{{ tn.stamm }}</td>
            <td><span *ngIf="tn.finalized == 0">Nein</span><span *ngIf="tn.finalized == 1">Ja</span></td>
        </tr>
      </tbody>
  
    </table>
  
  <div *ngIf="switch=='leader'">
    Gesamt Leitende: <b>{{totalofLeader}}</b>
    <a [routerLink]="" (click)="export('leader')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
  
  
    <table *ngIf="switch=='leader'" class="table table-bordered table-striped">
  
      <thead>
        <tr>
            <td>Nachname</td>
            <td>Vorname</td>
            <td>Stamm</td>
            <td>Abgeschlossen</td>
        </tr>
      </thead>
  
      <tbody>
        <ng-container *ngFor="let leader of leaders | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter, leader_leitung: filter, stufe: filter}">
          <tr>
            <td>{{ leader.nachname }}</td>
            <td>{{ leader.vorname }}</td>
            <td>{{ leader.stamm }}</td>
            <td><span *ngIf="leader.finalized == 0">Nein</span><span *ngIf="leader.finalized == 1">Ja</span></td>
          </tr>
            </ng-container>
      </tbody>
    </table>
    <div *ngIf="switch=='helfende'">
      Gesamt Helfende: <b>{{totalofHelfende}}</b>
      <a [routerLink]="" (click)="export('helfende')" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
    <table *ngIf="switch=='helfende'" class="table table-bordered table-striped">
      <thead>
        <tr>
            <td>Nachname</td>
            <td>Vorname</td>
            <td>Stamm</td>
            <td>Abgeschlossen</td>
        </tr>
      </thead>
  
      <tbody>
        <ng-container *ngFor="let helfender of helfende | async | filter:{nachname: filter, vorname: filter, bezirk: filter, stamm: filter}">
          <tr>
          <td>{{ helfender.nachname }}</td>
          <td>{{ helfender.vorname }}</td>
          <td>{{ helfender.stamm }}</td>
          <td><span *ngIf="helfender.finalized == 0">Nein</span><span *ngIf="helfender.finalized == 1">Ja</span></td>
          </tr>
            </ng-container>
      </tbody>
    </table>
    <div id="generierungen" *ngIf="switch=='gen'">
        <div class="card mx-auto">
          <div class="card-body text-left">
            <a [routerLink]="" (click)="rdp()" class="btn btn-default" role="button" aria-expanded="false"><i class="fa fa-file-pdf-o"></i> RDP-Listen generieren</a>
          </div>
        </div>
    </div>