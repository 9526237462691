
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Registration } from '../models/registration';
import { BackendService } from '../services/backend.service';
import { Settings } from '../classes/settings';

@Injectable()
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addRegistration(event_id: string, registration: Object): Observable<Registration[]> {
    return this.backendService.post('events/' + event_id + '/registrations/', registration)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

  getRegistrations(event_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/registrations')
       .map((response) => response);
  }

  getActiveRegistrations(event_id: string): Observable<any>{
    return this.backendService.get('events/' +  event_id + '/activeRegistrations')
       .map((response) => response);
  }

  getRegistration(event_id: string, registration: string): Observable<any> {
    return this.backendService.get('events/' + event_id + '/registrations/' + registration)
       .map((response) => response);
  }

  isRegistrationClosed(event_id: string, registration: string): Observable<any> {
    return this.backendService.get('events/' + event_id + '/isRegistrationClosed/' + registration)
       .map((response) => response);
  }

  updateRegistration(event_id: string, registration: Object): Observable<Registration[]> {
   const apiUrl = 'events';
   const url = `${apiUrl}/${event_id}/registrations/${registration['id']}`;
   return this.backendService.put(url, registration)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

 deleteRegistration(event_id: string, registration: string): Observable<any> {
   const apiUrl = 'events';
   const url = `${apiUrl}/${event_id}/registrations/${registration}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
}

}
