<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">{{registration.title | uppercase}}</h1>
    <h2 class="display-6">{{event.title | uppercase}}</h2>
  </div>
  <img src="assets/{{event.imageLink}}" alt="">
</div>
<form novalidate class="form-horizontal" (ngSubmit)="createSubscription(createSubscriptionForm.value)" #createSubscriptionForm="ngForm">
  <div *ngIf="registration.description" [innerHTML]="registration.description" class="alert alert-info">
  </div>
  <div class="form-group">
    <div class="mx-auto" *ngFor="let templateField of templateFields">
      <label for="{{templateField.title}}" class="col-lg-6 control-label">{{templateField.title}}</label>
      <div *ngIf="templateField.type == 'text'" class="col-lg-6">
        <input min="0" type="text" class="form-control" id="{{templatePage_id}}_{{templateField?.id}}" name="{{templatePage_id}}_{{templateField?.id}}" placeholder="{{templateField.title}}" ngModel #{{templatePage_id}}_{{templateField?.id}}="ngModel" (keyup)="updateField(templateField.id, NULL, $event)" [required]="templateField.required == 1">
      </div>
      <div *ngIf="templateField.type == 'textarea'" class="col-lg-6">
        <textarea name="name" class="form-control" rows="8" cols="80"id="{{templatePage_id}}_{{templateField?.id}}" name="{{templatePage_id}}_{{templateField?.id}}" placeholder="{{templateField.title}}" ngModel #{{templatePage_id}}_{{templateField?.id}}="ngModel" (keyup)="updateField(templateField.id, NULL, $event)" [required]="templateField.required == 1"></textarea>
      </div>
      <div *ngIf="templateField.type == 'number'" class="col-lg-6">
        <input min="0" type="number" class="form-control" id="{{templatePage_id}}_{{templateField?.id}}" name="{{templatePage_id}}_{{templateField?.id}}" ngModel #{{templatePage_id}}_{{templateField?.id}}="ngModel" (keyup)="updateField(templateField.id, NULL, $event)" [required]="templateField.required == 1">
      </div>
      <div *ngIf="templateField.type == 'option'" class="col-lg-6">
        <div class="col-12 row" *ngFor="let choice of templateField?.choices">
          <div class="col-2">
            <input min="0" type="checkbox" class="form-control" id="{{templatePage_id}}_{{templateField?.id}}" name="{{templatePage_id}}_{{templateField?.id}}" ngModel #{{templatePage_id}}_{{templateField?.id}}="ngModel" value="{{templateField.value}}" (change)="updateField(templateField.id, choice.name, $event)" [required]="templateField.required == 1">
          </div>
          <div class="col-10">
            {{choice.name}}
          </div>
        </div>
      </div>
      <div *ngIf="templateField.type == 'choice'" class="col-lg-6">
        <div class="col-12 row" *ngFor="let choice of templateField?.choices">
          <div class="col-2">
            <input min="0" type="radio" class="form-control" id="{{templatePage_id}}_{{templateField?.id}}" name="{{templatePage_id}}_{{templateField?.id}}" ngModel #{{templatePage_id}}_{{templateField?.id}}="ngModel" value="{{choice.name}}" (change)="updateField(templateField.id, choice.name, $event)" [required]="templateField.required == 1">
          </div>
          <div class="col-10">
            {{choice.name}}
          </div>
        </div>
      </div>

      </div>
  </div>
  <div class="form-group">
    <div class="col-lg-10 col-lg-offset-2">
      <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription']" class="btn btn-default">Abbrechen</a>
      <button type="submit" class="btn btn-primary" [disabled]="createSubscriptionForm.invalid">Absenden</button>
    </div>
  </div>

</form>
