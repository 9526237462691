
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplatePage } from '../models/templatePage';
import { BackendService } from '../services/backend.service';

@Injectable()
export class TemplatePageService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addTemplatePage(template_id: string, templatePage: Object): Observable<TemplatePage[]> {
    return this.backendService.post('templates/' + template_id + '/templatePages', templatePage)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
    }

  getTemplatePages(template_id: string): Observable<any>{
    return this.backendService.get('templates/'+ template_id + '/templatePages')
       .map((response) => response);
  }

  getTemplatePage(template_page:any): Observable<any>{
    return this.backendService.get('templatePages/' + template_page)
       .map((response) => response);
  }

  updateTemplatePage(template: Object, template_id: string): Observable<TemplatePage[]> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template_id}/templatePages/${template['id']}`;

   return this.backendService.put(url, template)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  deleteTemplatePage(template: string, template_id: string): Observable<any> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template_id}/templatePages/${template}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

}
