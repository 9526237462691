import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  bezirkSelected: any = true;
  check: any;
  user_id: any;
  user_admin: any;
  user: any;
  status: any;
  message: any;

  changePassword(data) {
      this.userService.changePassword(data)
      .subscribe(
        user => {
          this.notifyService.snotifySuccess(user['message'])
          this.router.navigate(['/']);
        },
        error => {
          console.log(<any>error);
          this.notifyService.snotifyError(error['message'])
        });
      }

  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getActualUser().subscribe(
      user => {
        this.user = user;
      },
      error => console.log(<any>error)
    )
  }

}



  
 
  