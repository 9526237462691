import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { Settings } from '../classes/settings';
import { BackendService } from '../services/backend.service';

@Injectable()
export class StammService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  getStaemme(): Observable<any>{
   return this.http.get(Settings.appURL + Settings.apiPath + 'public/staemme')
    .map((response) => response);
  }

  getStamm(stamm_id: string): Observable<any>{
    return this.backendService.get('staemme/' + stamm_id)
       .map((response) => response);
  }

}
