import { Component, OnInit, Input } from '@angular/core';
import {TemplateFieldService} from '../../../services/template-field.service';
import { TemplateField } from '../../../models/templateField';
import { Subscription } from '../../../models/subscription';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { TemplatePageService } from '../../../services/template-page.service';
import { UserService } from '../../../services/user.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { Router} from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { Event } from '../../../models/event';
import { RegistrationService } from '../../../services/registration.service';
import { Registration } from '../../../models/registration';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'app-events-registration-subscription-add',
  templateUrl: './events-registration-subscription-add.component.html',
  styleUrls: ['./events-registration-subscription-add.component.css']
})
export class EventsRegistrationSubscriptionAddComponent implements OnInit {
  templateFields: any;
  params: any;
  event_id: any;
  registration_id: any;
  templatePage_id: any;
  templatePages: any;
  user: any;
  subscription: any;
  event: any;
  registration: any;
  choices: any;

  constructor(
    private eventService: EventService,
    private registrationService: RegistrationService,
    private templateFieldService: TemplateFieldService,
    private activatedRoute: ActivatedRoute,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  createSubscription(subscriptiondata) {
    this.subscription.registration = this.registration_id
    this.subscription.user = this.user.id
    this.subscriptionService.addSubscription(this.event_id, this.registration_id, this.subscription)
    .subscribe(
      subscription => {
        this.notifyService.snotifySuccess("Anmeldung erfolgreich!")
        this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription']);
      },
      error => {
        this.notifyService.snotifyError("Anmeldung fehlerhaft!")
        console.log(<any>error)
      }
    );

  }

  updateField(field, option, event) {
    for (var i = 0; i < this.templateFields.length; i++){
     if (this.templateFields[i].id == field){
       if(this.templateFields[i].type == "option" || this.templateFields[i].type == "choice" ) {
         for (var j = 0; j < this.templateFields[i].choices.length; j++){
            if (this.templateFields[i].choices[j].name == option){
              this.templateFields[i].choices[j].selected = event.target.checked;
              this.subscription.data[this.templateFields[i].page + "_" + this.templateFields[i].id] = JSON.stringify(this.templateFields[i].choices)
            }
          }
       }
       else {
         this.subscription.data[this.templateFields[i].page + "_" + this.templateFields[i].id] = event.target.value
       }
     }
    }
  }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.templatePage_id = params['templatePage_id']);

    this.event = this.eventService.getEvent(this.event_id).subscribe(
      data => {
        this.event.title = data['title'];
        this.event.imageLink = data['imageLink'];
      },
      error => console.log(<any>error)
    );

    this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
      data => {
        this.subscription = new Subscription()
        this.subscription.data = {}
        this.registration.title = data['title'];
        this.registration.template = data['template'];
        this.registration.description = data['description'];

        this.templatePageService.getTemplatePages(this.registration.template).subscribe(
          pages => {
            pages.forEach(
              page => {
                this.templatePage_id = page.id
                this.templateFieldService.getTemplateFields(page.id).subscribe(
                  fields => {
                    fields.forEach(
                      field => {
                        if(field.type == "option" || field.type == "choice" ) {
                          field.choices = JSON.parse(field.choices)
                          this.subscription.data[field.page + "_" + field.id] = JSON.stringify(field.choices)
                        }
                        else {
                          this.subscription.data[field.page + "_" + field.id] = ""
                        }
                      }
                    )
                    this.templateFields=fields
                  }
                )

              }
            )
          }
        )
      },
      error => console.log(<any>error)
    );

    this.user = this.userService.getActualUser().subscribe(
      user => {
      this.user = user;
      this.user.id = this.user['id'];
       this.user.surname = this.user['surname'];
       this.user.givenname = this.user['givenname'];
       this.user.email = this.user['email'];
       this.user.stamm = this.user['stamm'];

    });
  }

}
