import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../services/registration.service';
import { Registration } from '../../models/registration';
import { EventService } from '../../services/event.service';
import { StammService } from '../../services/stamm.service';
import { TemplateService } from '../../services/template.service';
import { TemplatePageService } from '../../services/template-page.service';
import { SubscriptionService } from '../../services/subscription.service';
import { UserService } from '../../services/user.service';
import {Observable} from 'rxjs';
import { User } from '../../models/user';
import { Router} from '@angular/router';
import { Subscription } from '../../models/subscription';
import { NotifyService } from '../../services/notify.service';

@Component({
  selector: 'app-events-registrations-subscription',
  templateUrl: './events-registrations-subscription.component.html',
  styleUrls: ['./events-registrations-subscription.component.css']
})
export class EventsRegistrationsSubscriptionComponent implements OnInit {

  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  stamm:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  template: any;
  subscription_id: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  closed: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private subscriptionService: SubscriptionService,
    private templateService: TemplateService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);

    this.subscription = new Subscription;
    this.closed=0;


    this.event = this.eventService.getEvent(this.event_id).subscribe(
      data => {
        this.event.title = data['title'];
        this.event.imageLink = data['imageLink'];
        this.event.active = data['active']
      },
      error => console.log(<any>error)
    );

    this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
      data => {
        this.registration.title = data['title'];
        this.registration.template = data['template'];
        this.registration.description = data['description'];
        this.registration.active = data['active'];
        this.registrationService.isRegistrationClosed(this.event_id, this.registration_id).subscribe(
          data => {
            if (data === true) {
            this.closed=1;
            }
          }
        )

        this.template = this.templateService.getTemplate(this.registration.template).subscribe(
          data => {
            this.template.description = data['description'];
          }
        );
      },
      error => console.log(<any>error)
    );

    this.user = this.userService.getActualUser().subscribe(
      user => {
      this.user = user;
      this.user.id = this.user['id'];
       this.user.surname = this.user['surname'];
       this.user.givenname = this.user['givenname'];
       this.user.email = this.user['email'];
       this.user.stamm = this.user['stamm'];

       this.stamm = this.stammService.getStamm(this.user.stamm).subscribe(
         data => {
           this.stamm.title = data['title'];
         },
         error => console.log(<any>error)
       );
       this.subscription = this.subscriptionService.getSubscriptionOfUser(this.event_id, this.registration_id, '42', this.user.id).subscribe(
         data => {
           this.subscription = data;
           this.subscription_id = this.subscription['id'];
           this.subscription.nachname = this.subscription['nachname'];
           this.subscription.vorname = this.subscription['vorname'];
         },
         error => {
           console.log(<any>error)
           switch(this.registration_id) {
            case "1":
              this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/tydal-tn/add']);
              break;
            case "2":
              this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/tydal-leader/add']);
              break;
            case "3":
              this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/tydal-staff/add']);
              break;
            default:
              this.router.navigate(['events', this.event_id, 'registrations', this.registration_id, 'subscription', 'add']);
              break;
        
          }
           });
    });
  }
}
