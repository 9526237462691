import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { StammService } from '../../../services/stamm.service';
import { Stamm } from '../../../models/stamm';
import { Bezirk } from '../../../models/bezirk';
import { UserService } from '../../../services/user.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-user-create',
  templateUrl: './admin-user-create.component.html',
  styleUrls: ['./admin-user-create.component.css']
})
export class AdminUserCreateComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private stammService: StammService,
    private userService: UserService,
    private router: Router
  ) { }

   bezirkSelected: any = false;
   staemme:  Observable<Stamm[]>;
   checkPassword: boolean
   status: any;
   message: any;
   angular: any;

   ngOnInit() {
    this.staemme = this.stammService.getStaemme()
  }

   createUser(user) {
     user.admin = 0

       this.userService.addUser(user)
       .subscribe(
         user => {
           this.router.navigate(['/admin/users']);
         },
         error => {
           console.log(<any>error)
         this.status = 'error';
         this.message = error['message'];
       }
       );
     }


}
