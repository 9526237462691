<div class="jumbotron">
  <h1 class="display-4">{{event.title | uppercase}}</h1>
  <img src="assets/{{event.imageLink}}" alt="">
</div>
<div class="card-group">
<div *ngFor="let registration of registrations | async" class="mx-auto">
  <div class="card" style="width: 18rem; margin-bottom: 10px">
    <div class="card-body">
      <h5 class="card-title">{{registration.title}}</h5>
      <p class="card-text">{{registration.description}}</p>
     <a [routerLink]="[registration.id, 'subscription']" class="btn btn-primary">Anmeldung</a>
  </div>
</div>
  </div>
</div>
