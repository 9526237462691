<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/template/', template_id, 'page', templatePage, 'field', 'create']" class="btn btn-success">Neues Feld anlegen</a>
  </div>
</div>
<br />
<table class="table">
  <thead>
    <tr>
      <th>Feldname</th>
      <th>Typ</th>
      <th>Aktion</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let templateField of templateFields | async">
  <td>{{templateField.title}}</td>
  <td>{{templateField.type}}</td>
  <td>
    <a [routerLink]="['/admin/template/', template_id, 'page', templatePage, 'field', 'edit', templateField.id]" class="btn btn-warning"> Bearbeiten</a>
    <a [routerLink]="['/admin/template/', template_id, 'page', templatePage, 'field', 'delete', templateField.id]" class="btn btn-danger"> Löschen</a>
  </td>
</tr>
</tbody>
</table>
