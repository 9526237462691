<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Neues Passwort setzen</h1>
  </div>
</div>
<div class="mt-4 col-lg-6 offset-lg-3">
  <div class="card">
    <div class="card-body">
      <form (ngSubmit)="resetPassword(formularwerte)" [formGroup]="formularwerte">
        <fieldset>
        <div class="form-group col-lg-12">
          <label for="email" class="col-sm-12 col-form-label">E-Mail-Adresse</label>
          <div class="col-sm-12">
            <input autofocus type="email" name="email" class="form-control" id="email" placeholder="E-Mail-Adresse" formControlName="email" required>
          </div>
        </div>
        <div class="form-group col-lg-12">
          <label for="password" class="col-sm-12 col-form-label">Neues Passwort</label>
          <div class="col-lg-12">
            <input type="password" class="form-control" id="password" name="password" placeholder="Passwort" formControlName="password" minlength=6 required>
          </div>
        </div>
        <div class="form-group col-lg-12">
          <label for="password_confirmation" class="col-sm-12 col-form-label">Neues Passwort wiederholen</label>
          <div class="col-lg-12">
            <input type="password" class="form-control" id="password_confirmation" name="password_confirmation" placeholder="Passwort wiederholen" formControlName="password_confirmation" required>
          </div>
        </div>
        <div class="form-group col-lg-12">
          <div class="col-lg-12 col-lg-offset-2">
            <button type="submit" class="btn btn-primary my-3 float-left col-12 col-sm-5" [disabled]="!formularwerte.valid">Absenden</button>
            <a [routerLink]="['/login']" class="btn btn-outline-secondary my-3 col-12 float-right col-sm-5">Abbrechen</a>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>

