import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { StammService } from '../services/stamm.service';
import { Stamm } from '../models/stamm';
import { UserService } from '../services/user.service';
import {Observable} from 'rxjs';
import {NotifyService} from '../services/notify.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(
    private stammService: StammService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  staemme:  Observable<Stamm[]>;
  checkPassword: boolean
  status: any;
  message: any;
  angular: any;

  ngOnInit() {
    this.staemme = this.stammService.getStaemme()
  }

   createUser(user) {
      this.userService.addUser(user)
      .subscribe(
        user => {
          this.notifyService.snotifySuccess("Registrierung erfolgreich")
          this.router.navigate(['/login']);
        },
        error => {
          console.log(<any>error)
          this.notifyService.snotifyError(error['message'])
      }
      );
    }

}
