<div class="container">
    <div class="jumbotron row">
        <div class="display-4 col-md-10">
          <h1 class="display-4">Neue Anmeldung</h1>
          <h2 class="display-6">{{"Helfende" | uppercase}} | {{event.title | uppercase}}</h2>
        </div>
        <img class="col-md-2" src="assets/{{event.imageLink}}" alt="">
      </div>
    </div>
      <div class="col-md-10 col-md-offset-1">
        <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
        <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
        <div class="alert alert-info">
            Hallo liebe Helfende<br />

            Ein Haufen Pfadfinder*innen, wilde Aktionen, 12.08.-19.08.2023 und du bist dabei! Wir freuen uns sehr, dass du gemeinsam mit uns ein fantastisches Lager auf die Beine stellen willst.<br />

            Wir freuen uns auf ein unvergessliches Lager mit dir und bis dahin gut Pfad! Deine AG Helfende (<a href=mailto:helfende@sola2023.de>helfende@sola2023.de</a>)
        </div>
        <div class="alert alert-warning" *ngIf="subscription.finalized == 1">
          Deine Anmeldung ist erfolgreich abgeschickt - Du kannst keine Änderungen mehr vornehmen!
        </div>
        <div class="well well bs-component">
          <form novalidate class="form-horizontal" (ngSubmit)="addHelfende(addHelfendeForm.value)" #addHelfendeForm="ngForm">
            <fieldset [disabled]="subscription.finalized == 1">
              <div class="card mt-4">
                <div class="card-header">Zeiten & Aufgaben</div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="stammeslager" class="col-lg-12 control-label">Um das Lager herum nehme ich</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="stammeslager" name="stammeslager" [ngModel]="null" [(ngModel)]="subscription.stammeslager" #stammeslager="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="stammesvorlager">an einem Stammesvorlager teil</option>
                            <option value="stammesnachlager">an einem Stammesnachlager teil</option>
                            <option value="nichts">an nichts weiter teil</option>
                        </select>
                    </div>
                </div>
                  <div class="form-group">
                    <label for="stammesprogramm" class="col-lg-12 control-label">Ich möchte helfen, aber am 1,5-tägigen Stammesprogramm teilnehmen</label>
                    <div class="alert alert-light">
                      Der 5. und 6. Tag unseres Sommerlages wird Stammeszeit sein. Das heißt, es gibt keine Diözesanaktionen und es werden auch weniger Helfende benötigt. Du kannst dir wünschen, an den zwei Tagen vom Helfendensein befreit zu werden und stattdessen die Aktionen bei deinem Stamm mitzumachen bzw. deinem Stamm als Helfer*in zur Verfügung zu stehen. Wir können leider nicht garantieren, dass alle, die hier "Ja" anklicken, auch zum Stammesprogramm können, aber wir sind zuversichtlich.
                    </div>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="stammesprogramm" name="stammesprogramm" [ngModel]="null" [(ngModel)]="subscription.stammesprogramm" #stammesprogramm="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                  <label for="planung" class="col-lg-12 control-label">In meiner Planung bin ich</label>
                  <div class="alert alert-light">
                    Wenn du schon festgelegt bist auf die Tage, an denen Du auf dem Lager helfen kannst, dann gib die genauen Tage gerne in der Abfrage "Ich kann an folgenden Tagen helfen" an. Solltest du noch flexibel darin sein, an welchen Tagen du genau helfen kannst, dann gib gerne an, für wie viele Tage du ca. helfen könntest bei der Tagesabfrage - wir melden uns dann bei dir. Wenn der Fall komplizierter sein sollte als das, dann schreib uns gerne eine Mail an <a href="mailto:helfende@sola2023.de">helfende@sola2023.de</a>.<br />
                    In der Vergangenheit hatten wir oft Schwierigkeiten, auch die Abbautage mit genügend Helfenden zu besetzen. Wir freuen uns deswegen, wenn du dir vorstellen kannst, (auch) zu dieser Zeit zu helfen. Gegebenenfalls hast du Anrecht auf (bezahlten) Sonderurlaub für das Helfen auf dem Lager. Bei Fragen hierzu schreib uns gern eine Mail!
                  </div>
                  <div class="col-lg-10">
                      <select class="form-control" rows="2" id="planung" name="planung" [ngModel]="null" [(ngModel)]="subscription.planung" #planung="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="flexibel">mit meinem Urlaub noch flexibel</option>
                          <option value="festgelegt">schon festgelegt auf die Tage, an denen ich helfen kann</option>
                      </select>
                  </div>
              </div>
                  <div class="form-group">
                    <label for="akag" class="col-lg-12 control-label">Ich kann an folgenden Tagen helfen</label>

                    <div class="col-lg-10 row" *ngFor="let tageOption of tageOptions">
                        <div class="col-1">
                        <input type="checkbox" id="tageOptions" name="akag" value="{{tageOption.name}}" [checked]="tageOption.selected == true" (change)="updateCheckedtage(tageOption.name, $event)">
                        </div>
                        <div class="col-10">
                        {{tageOption.name}}
                        </div>
                      </div>
                      </div>
                    
                    <div class="form-group">
                      <label for="urlaub" class="col-lg-10 control-label">Ich kann x Tage Urlaub flexibel nehmen:</label>
                      <div class="col-lg-10">
                        <input type="number" class="form-control" id="urlaub" name="urlaub" [(ngModel)]="subscription.urlaub" #urlaub="ngModel">
                      </div>
                    </div>  
                  <div class="form-group">
                    <label for="akag" class="col-lg-12 control-label">Ich bin als Mitglied in folgendem AK/AG auf dem Lager</label>
                    <div class="col-lg-10 alert alert-light">
                      Du bist bereits Mitglied in einem AK/einer AG oder in einem AK-Plus und gestaltest das Sommerlager jetzt schon aktiv mit? Dann sag uns doch bitte, zu welchem AK/welcher AG du gehörst. Wunscheinsatzbereiche können unten angeben werden!
                    </div>
                    <div class="col-lg-10 row" *ngFor="let akagOption of akagOptions">
                        <div class="col-1">
                          <input type="checkbox" id="akagOptions" name="akag" value="{{akagOption.name}}" [checked]="akagOption.selected == true" (change)="updateCheckedakag(akagOption.name, $event)">
                        </div>
                        <div class="col-10">
                          {{akagOption.name}}
                        </div>

                    </div>
                  </div>

                    <div class="form-group">
                      <label for="akag" class="col-lg-12 control-label">Ich möchte eingesetzt werden in folgendem Wunschbereich</label>
                      <div class="col-lg-10 alert alert-light">
                        Wir bemühen uns sehr, allen Wünschen bestmöglich nachzukommen, können aber nicht immer dafür garantieren. Was wir aber versprechen können: Du kannst, auch während des Lagers, mit uns über alles reden.
                      </div>
                      <div class="form-group">

                        <label for="1wunschbereich" class="col-lg-10 control-label">1. Wunschbereich</label>
                        <div class="col-lg-10">
                            <select class="form-control" rows="2" id="1wunschbereich" name="1wunschbereich" [ngModel]="null" [(ngModel)]="subscription['1wunschbereich']" #1wunschbereich="ngModel" required>
                                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                                <option *ngFor="let wunschbereichOption of wunschbereichOptions" value="{{wunschbereichOption.name}}">{{wunschbereichOption.name}}</option>
                            </select>
                        </div>
                      </div>
                      <div class="form-group">

                        <label for="2wunschbereich" class="col-lg-10 control-label">2. Wunschbereich</label>
                        <div class="col-lg-10">
                            <select class="form-control" rows="2" id="2wunschbereich" name="2wunschbereich" [ngModel]="null" [(ngModel)]="subscription['2wunschbereich']" #2wunschbereich="ngModel" required>
                                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                                <option *ngFor="let wunschbereichOption of wunschbereichOptions" value="{{wunschbereichOption.name}}">{{wunschbereichOption.name}}</option>
                            </select>
                        </div>
                      </div>
                      <div class="form-group">

                        <label for="3wunschbereich" class="col-lg-10 control-label">3. Wunschbereich</label>
                        <div class="col-lg-10">
                            <select class="form-control" rows="2" id="3wunschbereich" name="3wunschbereich" [ngModel]="null" [(ngModel)]="subscription['3wunschbereich']" #3wunschbereich="ngModel" required>
                                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                                <option *ngFor="let wunschbereichOption of wunschbereichOptions" value="{{wunschbereichOption.name}}">{{wunschbereichOption.name}}</option>
                            </select>
                        </div>
                      </div>
                      </div>

                      <div class="form-group">
                        <label for="kompetenzen" class="col-lg-12 control-label">Ich bringe folgende Qualifikationen oder Kompetenzen mit, die hilfreich sein könnten:
                        <div class="col-lg-10 alert alert-light">
                          Zum Beispiel Qualifikationen wie Rettungsschwimmer, Führerscheine, Handwerksfähigkeiten, Medizinische Fachkenntnisse, Feuerwehrfähigkeiten, THW-Wissen, staatliche Erlaubnisse (Hygieneschulung, ...) – vielleicht fehlen bei den Beispielen aber auch krasse Kompetenzen, die wir nicht erahnen – nenne gerne einfach alles was du erwähnenswert findest!
                        </div>
                        </label>
                        <div class="col-lg-10">
                          <textarea class="form-control" id="kompetenzen" name="kompetenzen" placeholder="Qualifikationen und Kompetenzen" [(ngModel)]="subscription.kompetenzen" #kompetenzen="ngModel"></textarea>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="infektionsschutzbelehrung" class="col-lg-10 control-label">Ich habe eine aktuell gültige Infektionsschutzbelehrung nach §43 Abs. 1 des Infektionsschutzgesetzes (sog. "Hygeineschutzbelehrung" für das Helfen in der Küche)</label>
                        <div class="col-lg-10">
                            <select class="form-control" rows="2" id="infektionsschutzbelehrung" name="infektionsschutzbelehrung" [ngModel]="null" [(ngModel)]="subscription.infektionsschutzbelehrung" #infektionsschutzbelehrung="ngModel" required>
                                <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                                <option value="1">Ja</option>
                                <option value="0">Nein</option>
                            </select>
                        </div>
                      </div>
                </div>
              </div>

              <div class="card mt-4">
                <div class="card-header">An- & Abreise</div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="anreise" class="col-lg-10 control-label">Ich reise folgendermaßen an</label>
                    <div class="col-lg-10 alert alert-light">
                      Alle Helfenden werden in den nächsten Monaten noch detailliertere Informationen zu An- und Abreisemöglichkeit und der Übernahme von Fahrtkosten bekommen. Falls ihr aber jetzt schon wisst, wie ihr hin und zurück kommt, gebt das gerne hier an, damit wir damit planen können.                    </div>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="anreise" name="anreise" [ngModel]="null" [(ngModel)]="subscription.anreise" #anreise="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="stamm">Mit meinem Stamm</option>
                            <option value="zug">Mit dem Zug</option>
                            <option value="pkw">Mit einem privaten PKW</option>
                            <option value="geplant">Schon geplant (z.B. Ich fahre einen LKW)</option>
                            <option value="keineahnung">Keine Ahnung</option>
                        </select>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="anreise.value=='zug'">
                    <label for="anreise_shuttle" class="col-lg-10 control-label">Shuttle oder laufen</label>
                    <div class="col-lg-10">
                      <select class="form-control" rows="2" id="anreise_shuttle" name="anreise_shuttle" [ngModel]="null" [(ngModel)]="subscription.anreise_shuttle" #anreise_shuttle="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="shuttle">Ich brauche einen Shuttle</option>
                          <option value="laufen">Ich kann den Weg (ca. 5km) auch laufen</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="anreise.value=='pkw'">
                    <label for="anreise_pkw" class="col-lg-10 control-label">Ich habe x Plätze im PKW frei</label>
                    <div class="col-lg-10">
                      <input type="number" class="form-control" id="anreise_pkw" name="anreise_pkw" [(ngModel)]="subscription.anreise_pkw" #anreise_pkw="ngModel" required>
                    </div>
                  </div>

              <div class="form-group">
              <label for="abreise" class="col-lg-10 control-label">Ich reise folgendermaßen ab</label>
              <div class="col-lg-10">
                  <select class="form-control" rows="2" id="abreise" name="abreise" [ngModel]="null" [(ngModel)]="subscription.abreise" #abreise="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="stamm">Mit meinem Stamm</option>
                      <option value="zug">Mit dem Zug</option>
                      <option value="pkw">Mit einem privaten PKW</option>
                      <option value="geplant">Schon geplant (z.B. Ich fahre einen LKW)</option>
                      <option value="keineahnung">Keine Ahnung</option>
                  </select>
              </div>
            </div>

            <div class="form-group" *ngIf="abreise.value=='zug'">
              <label for="abreise_shuttle" class="col-lg-10 control-label">Shuttle oder laufen</label>
              <div class="col-lg-10">
                <select class="form-control" rows="2" id="abreise_shuttle" name="abreise_shuttle" [ngModel]="null" [(ngModel)]="subscription.abreise_shuttle" #abreise_shuttle="ngModel" required>
                    <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                    <option value="shuttle">Ich brauche einen Shuttle</option>
                    <option value="laufen">Ich kann den Weg (ca. 5km) auch laufen</option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="abreise.value=='pkw'">
              <label for="abreise_pkw" class="col-lg-10 control-label">Ich habe x Plätze im PKW frei</label>
              <div class="col-lg-10">
                <input type="number" class="form-control" id="abreise_pkw" name="abreise_pkw" [(ngModel)]="subscription.abreise_pkw" #abreise_pkw="ngModel" required>
              </div>
            </div>
          </div>
        </div>

              <div class="card mt-4">
                <div class="card-header">Unterbringung</div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="schlafsituation" class="col-lg-10 control-label">Zur Schlafsituation der Helfenden</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="schlafsituation" name="schlafsituation" [ngModel]="null" [(ngModel)]="subscription.schlafsituation" #schlafsituation="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="braucheBett">Ich brauche ein Bett!</option>
                            <option value="haettegerneBett">Ich hätte gern ein Bett</option>
                            <option value="stamm">Ich schlafe bei meinem Stamm</option>
                            <option value="helfendenzelt">Ich schlafe gern im Helfendenzelt</option>
                        </select>
                    </div>
                  </div>
                  <div class="form-group" >
                    <label for="geschlechtmix" class="col-lg-10 control-label">Ich bin mit geschlechtergemischter Unterbringung</label>
                      <div class="col-lg-10">
                        <select class="form-control" rows="2" id="geschlechtmix" name="geschlechtmix" [ngModel]="null" [(ngModel)]="subscription.geschlechtmix" #geschlechtmix="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">einverstanden</option>
                            <option value="0">nicht einverstanden</option>
                        </select>
                      </div>
                  </div>
                </div>
              </div>

              <div class="card mt-4">
                <div class="card-header">Informationen zum Helfendenbeitrag</div>
                <div class="card-body">
                    <div class="alert">
                      <p>Das Lager ist für Helfende komplett kostenlos. Hurra! Wenn du trotzdem unsere Diözese mit einer Spende unterstützen möchtest, kannst du dies auf diesem Konto tun</p>
                      <ul class="list-group">
                        <li class="list-group-item flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">Kontoinhaber</h6>
                          </div>
                          <p class="mb-1">Diözesanverband St. Georg im Erzbistum Hamburg e. V.</p>
                        </li>
                        <li class="list-group-item flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">IBAN</h6>
                          </div>
                          <p class="mb-1">DE12 2005 0550 1318 1239 89</p>
                        </li>
                        <li class="list-group-item flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">BIC</h6>
                          </div>
                          <p class="mb-1">DHASPDEHHXXX</p>
                        </li>
                        <li class="list-group-item flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">Bank</h6>
                          </div>
                          <p class="mb-1">Hamburger Sparkasse   </p>
                        </li>
                      </ul>
                      <p class="mt-3">In der Vergangenheit wurde sich eine Orientierungshilfe zur Höhe einer angemessenen Spende gewünscht. Das ist für uns schwer, weil unterschiedliche Helfende unterschiedliche Anteile des Lagers mitbekommen. Die realen Kosten pro Person belaufen sich auf rund 300€.</p>
                    </div>
                    <div class="form-group">
                      <label for="spendenquittung" class="col-lg-10 control-label">Ich benötige eine Spendenquittung</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="spendenquittung" name="spendenquittung" [ngModel]="null" [(ngModel)]="subscription.spendenquittung" #spendenquittung="ngModel" required>
                              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                              <option value="1">Ja</option>
                              <option value="0">Nein</option>
                          </select>
                      </div>
                    </div>
                </div>
              </div>
              
              <div class="card mt-4">
                <div class="card-header">Messenger & Kinderbetreuung</div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="messenger" class="col-lg-12 control-label">Gibt es Messenger, über die wir dich kontaktieren dürfen?</label>
                    <div class="col-lg-10 row" *ngFor="let messengerOption of messengerOptions">
                      <div class="col-1">
                        <input type="checkbox" id="messengerOptions" name="messenger" value="{{messengerOption.name}}" [checked]="messengerOption.selected == true" (change)="updateCheckedmessenger(messengerOption.name, $event)">
                      </div>
                      <div class="col-10">
                        {{messengerOption.name}}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="kinderbetreuung" class="col-lg-10 control-label">Ich brauche eine Kinderbetreuung für Kinder unter 6 Jahren:</label>
                    <div class="alert alert-light">
                      Während der Einsatzschichten der Helfenden wird eine Kinderbetreuung eingerichtet für Kinder, die noch nicht an dem Programm teilnehmen. Bitte melde diese Kinder separat als reguläre Teilnehmer*innen an und gib als Stufe "Kinderbetreuung" an und benenne sie hier. Du musst dich vor deinen Kindern anmelden.
                    </div>
                    <div class="col-lg-10">
                      <input type="text" class="form-control" id="kinderbetreuung" name="kinderbetreuung" placeholder="Name1, Name2" [(ngModel)]="subscription.kinderbetreuung" #kinderbetreuung="ngModel">
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-4">
                  <div class="card-header">Lebensmittelabfragen</div>
                  <div class="card-body">
                  <div class="form-group">
                    <div class="alert alert-light">
                      Wir wollen unser Lager möglichst nachhaltig gestalten, weswegen wir nur wenig Fleisch kaufen und essen werden. Insofern entspricht die Option "Ich würde mich freuen, auch mal Fleisch zu essen." also keiner Fleischbestellung. Lebensmittelwünsche müssen korrekt angegeben werden, da wir sonst auf dem Lager nicht je genügend Portionen bereithalten können, ohne am Ende viel wegschmeißen zu müssen.              
                    </div>
                      <label for="lebensmVerpf" class="col-lg-12 control-label">Standardmäßig ist die Verpflegung vegetarisch.</label>
                      <div class="col-lg-10 row" *ngFor="let lebensmVerpfOption of lebensmVerpfOptions">
                          <div class="col-1">
                          <input type="checkbox" id="lebensmVerpfOptions" name="lebensmVerpf" value="{{lebensmVerpfOption.name}}" [checked]="lebensmVerpfOption.selected == true" (change)="updateCheckedlebensmVerp(lebensmVerpfOption.name, $event)">
                          </div>
                          <div class="col-10">
                          {{lebensmVerpfOption.name}}
                          </div>
                      </div>
                      </div>
                    <div class="form-group">
                      <label for="lebensmUnvert" class="col-lg-12 control-label">Lebensmittelunverträglichkeiten</label>
                        <div class="col-lg-10 row" *ngFor="let lebensmUnvertOption of lebensmUnvertOptions">
                          <div class="col-1">
                            <input type="checkbox" id="lebensmUnvertOptions" name="lebensmUnvert" value="{{lebensmUnvertOption.name}}" [checked]="lebensmUnvertOption.selected == true" (change)="updateCheckedlebensmUnvert(lebensmUnvertOption.name, $event)">
                            </div>
                            <div class="col-10">
                            {{lebensmUnvertOption.name}}
                            </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="lebensmSonsUnvert" class="col-lg-12 control-label">Sonstige Lebensmittelunverträglichkeiten</label>
                      <div class="col-lg-10">
                        <input type="text" class="form-control" id="lebensmSonsUnvert" name="lebensmSonsUnvert" placeholder="Sonstige Unverträglichkeiten" [(ngModel)]="subscription.lebensmSonsUnvert" #lebensmSonsUnvert="ngModel">
                        <div *ngIf="lebensmSonsUnvert.errors?.required && lebensmSonsUnvert.dirty" class="alert alert-danger">
                          Sonstige Lebensmittelunverträglichkeiten ist ein Pflichtfeld
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-4">
                  <div class="card-header">Medizinische Angaben</div>
                  <div class="card-body">
                  <div class="form-group">
                      <label for="krankheiten" class="col-lg-12 control-label">Nennenswerte Krankheiten</label>
                      <div class="alert alert-light">
                        Auf unserem Lager wird ein Sanitätsteam für uns da sein. Damit wir uns bestmöglich um dich kümmern können, bitten wir dich, hier alle dafür wichtigen Krankheiten aufzuzählen.
                      </div>
                      <div class="col-lg-10">
                              <input type="text" class="form-control" id="krankheiten" name="krankheiten" placeholder="Krankheiten" [(ngModel)]="subscription.krankheiten" #krankheiten="ngModel">
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="medikamente" class="col-lg-12 control-label">Regelmäßige Medikamente</label>
                      <div class="alert alert-light">
                        Bitte gib hier an, ob du regelmäßig Medikamente nehmen musst und ob diese Kühlung benötigen.
                      </div>
                      <div class="col-lg-10">
                              <input type="text" class="form-control" id="medikamente" name="medikamente" placeholder="Medikamente" [(ngModel)]="subscription.medikamente" #medikamente="ngModel">
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="allergien" class="col-lg-12 control-label">Allergien</label>
                      <div class="col-lg-10">
                              <input type="text" class="form-control" id="allergien" name="allergien" placeholder="Allergien" [(ngModel)]="subscription.allergien" #allergien="ngModel">
                      </div>
                  </div>
                  <div class="form-group">
                    <label for="tetanus" class="col-lg-10 control-label">Ich habe zum Lager eine gültige Tetanusimpfung</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="tetanus" name="tetanus" [ngModel]="null" [(ngModel)]="subscription.tetanus" #tetanus="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="infektionsschutz" class="col-lg-12 control-label"></label>
                    <div class="col-lg-10 row">
                      <div class="col-1">
                        <input type="checkbox" id="infektionsschutz" name="infektionsschutz" [ngModel]=null [(ngModel)]="subscription.infektionsschutz" #infektionsschutz="ngModel" [value]="infektionsschutz" [required]="infektionsschutz.value != true">
                      </div>
                      <div class="col-10">
                        Ich habe das <a href="https://www.rki.de/DE/Content/Infekt/IfSG/Belehrungsbogen/belehrungsbogen_eltern_deutsch.pdf?__blob=publicationFile">Merkblatt zum Infektionsschutz</a> zur Kenntnis genommen.<br />
                      </div>

                      <div *ngIf="infektionsschutz.errors?.required && infektionsschutz.dirty" class="alert alert-danger col-lg-12">
                        Infektionsschutz ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                <div class="card mt-4">
                  <div class="card-header">Notfallkontakt</div>
                  <div class="card-body">
                    <div class="alert alert-light col-lg-10">
                      Trage hier den Namen und die Telefonnummer der Person ein, die wir bei einem Notfall erreichen können.
                    </div>
                    <div class="form-group">
                      <label for="notfall_name" class="col-lg-10 control-label">Name</label>
                      <div class="col-lg-10">
                        <input type="text" class="form-control" id="notfall_name" name="notfall_name" placeholder="Name" [(ngModel)]="subscription.notfall_name" #notfall_name="ngModel" required>
                        <div *ngIf="notfall_name.errors?.required && notfall_name.dirty" class="alert alert-danger">
                          Name ist ein Pflichtfeld
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="notfall_telefonnummer" class="col-lg-10 control-label">Telefonnummer</label>
                      <div class="col-lg-10">
                        <input type="text" class="form-control" id="notfall_telefonnummer" name="notfall_telefonnummer" placeholder="Telefonnummer" [(ngModel)]="subscription.notfall_telefonnummer" #notfall_telefonnummer="ngModel" required>
                        <div *ngIf="notfall_telefonnummer.errors?.required && notfall_telefonnummer.dirty" class="alert alert-danger">
                          Telefonnummer ist ein Pflichtfeld
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-4">
                  <div class="card-header">Veröffentlichungen</div>
                  <div class="card-body">
                  <div class="form-group">
                  <div class="form-group">
                      <label for="pr" class="col-lg-10 control-label">Es wird zugestimmt, dass von der angemeldeten Person Aufnahmen erstellt und der DPSG Hamburg unentgeltlich für die je untengenannten Zwecke zur Verfügung gestellt werden:</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="pr" name="pr" [ngModel]="null" [(ngModel)]="subscription.pr" #pr="ngModel" required>
                              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                              <option value="1">Ja</option>
                              <option value="0">Nein</option>
                          </select>
                      </div>
                  </div>
                  <hr />
                  <div class="form-group">
                    <label for="pr_intern" class="col-lg-10 control-label">Interne Veröffentlichung bspw. als Download-Link für Teilnehmende der Veranstaltungen für private Zwecke</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="pr_intern" name="pr_intern" [ngModel]="null" [(ngModel)]="subscription.pr_intern" #pr_intern="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </div>
                </div>
                <hr />
                <div class="form-group">
                  <label for="pr_presse" class="col-lg-10 control-label">Veröffentlichungen in Medien (bspw. Pressefotos)</label>
                  <div class="col-lg-10">
                      <select class="form-control" rows="2" id="pr_presse" name="pr_presse" [ngModel]="null" [(ngModel)]="subscription.pr_presse" #pr_presse="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="1">Ja</option>
                          <option value="0">Nein</option>
                      </select>
                  </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="pr_internet" class="col-lg-10 control-label">Veröffentlichungen im Internet (auf den Homepages dpsg-hamburg.de und operation-kronos.de der DPSG Hamburg)</label>
                <div class="alert alert-light">
                  Die angemeldete Person stimmt der Nutzung von Fotos und Filmen auch bei Ergänzung, Bearbeitung oder Entfernung von Bildbestandteilen zu. Die DPSG Hamburg verpflichtet sich, keine entstellenden oder unangemessenen Bilder von der Person zu veröffentlichen, sondern diese zu löschen. Die Zustimmung zur Veröffentlichung einzelner oder aller Aufnahmen kann jederzeit widerrufen werden. Die Veröffentlichungen werden - sofern möglich (bspw. nicht bei bereits veröffentlichten Printprodukten) - auf Anfrage umgehend gelöscht.
                </div>
                <div class="col-lg-10">
                    <select class="form-control" rows="2" id="pr_internet" name="pr_internet" [ngModel]="null" [(ngModel)]="subscription.pr_internet" #pr_internet="ngModel" required>
                        <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                        <option value="1">Ja</option>
                        <option value="0">Nein</option>
                    </select>
                </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="pr_social" class="col-lg-10 control-label">Veröffentlichungen im Internet (in den sozialen Medien, insbesondere Facebook und Instagram)</label>
              <div class="alert alert-light">
                Die angemeldete Person stimmt der Nutzung von Fotos und Filmen auch bei Ergänzung, Bearbeitung oder Entfernung von Bildbestandteilen zu. Die DPSG Hamburg verpflichtet sich, keine entstellenden oder unangemessenen Bilder von der Person zu veröffentlichen, sondern diese zu löschen. Die Zustimmung zur Veröffentlichung einzelner oder aller Aufnahmen kann jederzeit widerrufen werden. Die Veröffentlichungen werden - sofern möglich (bspw. nicht bei bereits veröffentlichten Printprodukten) - auf Anfrage umgehend gelöscht.
              </div>
              <div class="col-lg-10">
                  <select class="form-control" rows="2" id="pr_social" name="pr_social" [ngModel]="null" [(ngModel)]="subscription.pr_social" #pr_social="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="1">Ja</option>
                      <option value="0">Nein</option>
                  </select>
              </div>
          </div>
                </div>
                </div>
                </div>

                <div class="card mt-4">
                  <div class="card-header">Sonstiges</div>
                  <div class="card-body">
                    <div class="form-group">
                      <label for="foerderung" class="col-lg-12 control-label">Ich stimme der Nutzung und Weitergabe der hier angegebenen Daten zum Zwecke der finanziellen Förderung der Veranstaltung an berechtigte Dritte zu. Das sind insbesondere die Stadt Hamburg bzw. die für den Wohnort zuständige Sozialbehörde, das Bonifatiuswerk sowie die Landesarbeitsgemeinschaften des BDKJ in Hamburg und Schleswig-Holstein</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="foerderung" name="foerderung" [ngModel]="null" [(ngModel)]="subscription.foerderung" #foerderung="ngModel" required>
                              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                              <option value="1">Ja</option>
                              <option value="0">Nein</option>
                          </select>
                      </div>
                  </div>
                  <hr/>
                  <div class="form-group">
                      <label for="schwimmen" class="col-lg-10 control-label">Ich kann schwimmen</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="schwimmen" name="schwimmen" [ngModel]="null" [(ngModel)]="subscription.schwimmen" #schwimmen="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="1">Ja</option>
                          <option value="0">Nein</option>
                          </select>
                      </div>
                  </div>
                    <hr />
                  <div class="form-group">
                      <label for="fuerungszeugnis" class="col-lg-10 control-label">Zurzeit liegt die Einsicht in mein erweitertes Führungszeugnis</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="fuerungszeugnis" name="fuerungszeugnis" [ngModel]="null" [(ngModel)]="subscription.fuerungszeugnis" #fuerungszeugnis="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="stamm">im Stamm vor</option>
                          <option value="dv">im Diözesanverband Hamburg vor</option>
                          <option value="anderedv">in einer anderen Diözese vor</option>
                          <option value="nochnicht">noch nicht vor</option>
                          </select>
                      </div>
                  </div>
                  <hr />
                  <div class="form-group">
                      <label for="praenvention" class="col-lg-10 control-label">Zurzeit liegt mein Präventionsschulungszertifikat (nicht älter als fünf Jahre)</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="praenvention" name="praenvention" [ngModel]="null" [(ngModel)]="subscription.praenvention" #praenvention="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="nami">in der NaMi vor (beste Möglichkeit!)</option>
                          <option value="stamm">im Stamm vor</option>
                          <option value="dv">im Diözesanverband Hamburg vor</option>
                          <option value="anderedv">in einer anderen Diözese vor</option>
                          <option value="nochnicht">noch nicht vor</option>
                          </select>
                      </div>
                  </div>
                  <hr />
                  <div class="form-group">
                      <label for="sonstiges" class="col-lg-12 control-label">Gibt es noch Anmerkungen, die du an dieser Stelle loswerden möchtest?

                      </label>
                      <div class="col-lg-10">
                        <textarea class="form-control" id="sonstiges" name="sonstiges" placeholder="Anmerkungen" [(ngModel)]="subscription.sonstiges" #sonstiges="ngModel"></textarea>
                      </div>
                    </div>
                </div>
                </div>
                <div class="container">
                  <div class="form-group">
                    <label for="schutzkonzept" class="col-lg-12 control-label"></label>
                    <div class="col-lg-10 row">
                      <div class="col-1">
                        <input type="checkbox" id="schutzkonzept" name="schutzkonzept" [ngModel]=null [(ngModel)]="subscription.schutzkonzept" #schutzkonzept="ngModel" [value]="schutzkonzept" [required]="schutzkonzept.value != true">
                      </div>
                      <div class="col-10">
                        Ich habe das <a href="http://www.dpsg-hamburg.de/themen/praevention/downloads/schutzkonzept-stand-14-07-2021/download" targe="_blank" >institutionelle Schutzkonzept</a> zur Prävention sexualisierter Gewalt der DPSG Hamburg gelesen und bestätige, mich an dieses und besonders an den enthaltenen Verhaltenskodex zu halten.<br />
                      </div>

                      <div *ngIf="schutzkonzept.errors?.required && schutzkonzept.dirty" class="alert alert-danger col-lg-12">
                        Schutzkonzept ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
            </div>

                    <div class="container">
                      <div class="form-group">
                        <label for="agb" class="col-lg-12 control-label"></label>
                        <div class="col-lg-10 row">
                          <div class="col-1">
                            <input type="checkbox" id="agb" name="agb" [ngModel]=null [(ngModel)]="subscription.agb" #agb="ngModel" [value]="agb" [required]="agb.value != true">
                          </div>
                          <div class="col-10">
                            Ich bestätige die <a href="/assets/docs/tydal/AGB.pdf" target="_blank">AGB</a> und habe die <a href="/assets/docs/tydal/Datenschutzerklaerung_KDG.pdf" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen<br />
                          </div>

                          <div *ngIf="agb.errors?.required && agb.dirty" class="alert alert-danger col-lg-12">
                            AGB ist ein Pflichtfeld
                          </div>
                        </div>
                      </div>
                </div>





            </fieldset>
            <div class="form-group">
              <div class="col-lg-10 col-lg-offset-2">
                <a [routerLink]="['/events/custom/tydal/registrations']" class="btn btn-outline-secondary col-12 col-sm-3">Abbrechen</a>
                <button class="btn btn-primary my-3 my-sm-0 mx-sm-5 col-12 col-sm-3" [disabled]="addHelfendeForm.invalid || subscription.finalized == 1">Speichern</button>
                <button (click)="finalizeHelfende(addHelfendeForm.value)" class="btn btn-danger col-12 col-sm-3" [disabled]="addHelfendeForm.invalid || subscription.id == 0 || subscription.finalized == 1">Absenden</button>
              </div>
            </div>
          </form>

        </div>
      </div>

