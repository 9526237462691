<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">{{registration.title | uppercase}}</h1>
    <h2 class="display-6">{{event.title | uppercase}}</h2>
  </div>
<img src="assets/{{event.imageLink}}" alt="">
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
      <fieldset>
        <div class="form-group">
          <label for="imageLink" class="col-lg-2 control-label">Name</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="{{user.surname}} {{user.givenname}}" readonly>
          </div>
        </div>
        <div class="form-group">
          <label for="imageLink" class="col-lg-2 control-label">E-Mail</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="{{user.email}}" readonly>
          </div>
        </div>
        <div class="form-group">
          <label for="imageLink" class="col-lg-2 control-label">Stamm</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="{{stamm?.title}}" readonly>
          </div>
        </div>
        <br />
        <div *ngIf="subscription_id">

          Deine Anmeldung:
          <br />
          <div class="col-md-12 col-md-offset-1">
            <div class="table-responsive">
              <table class="table table-users">
                <thead>
                  <tr>
                    <th>Nachname</th>
                    <th>Vorname</th>
                    <th>Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{user.givenname}}</td>
                    <td>{{user.surname}}</td>
                    <td>
                    <!-- <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', subscription_id, 'edit']" class="btn btn-warning btn-users" *ngIf="event.active == 1 && registration.active == 1 && closed == 0"> Bearbeiten</a>-->
                     <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', subscription_id, 'del']" class="btn btn-danger btn-users" *ngIf="event.active == 1 && registration.active == 1 && closed == 0"> Löschen</a>
                   </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          </div>

      </fieldset>
  </div>
</div>
