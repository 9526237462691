import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';
import { TemplatePageService } from '../../../services/template-page.service';
import { UserService } from '../../../services/user.service';
import { Router} from '@angular/router';
import { Subscription } from '../../../models/subscription';
import { NotifyService } from '../../../services/notify.service';
import { TydalService } from '../../../services/tydal.service';

@Component({
  selector: 'app-tydal-add-helfende',
  templateUrl: './tydal-add-helfende.component.html',
  styleUrls: ['./tydal-add-helfende.component.css']
})
export class TydalAddHelfendeComponent implements OnInit {
  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  istGeschwister: boolean;

  lebensmUnvertOptions = [
    { name: 'Gluten', selected: false },
    { name: 'Eier', selected: false },
    { name: 'Fische', selected: false },
    { name: 'Erdnüsse', selected: false },
    { name: 'Sojabohnen', selected: false },
    { name: 'Laktose', selected: false },
    { name: 'Sellerie', selected: false },
    { name: 'Senf', selected: false },
    { name: 'Sesamsamen', selected: false },
    { name: 'Schwefeldioxid und Sulphite in Konzentrationen', selected: false },
    { name: 'Lupinen', selected: false},
  ]

  lebensmVerpfOptions = [
    { name: 'Ich würde gerne vegan essen.', selected: false },
    { name: 'Ich würde mich freuen, auch mal Fleisch zu essen.', selected: false },
  ]

  messengerOptions = [
    { name: 'WhatsApp', selected: false },
    { name: 'Signal', selected: false },
    { name: 'Telegram', selected: false },
    { name: 'Threema', selected: false }
  ]

  akagOptions = [
    { name: 'AK GO', selected: false },
    { name: 'Rover AK', selected: false },
    { name: 'Pfadi AK', selected: false },
    { name: 'Jufi AK', selected: false },
    { name: 'Wö AK', selected: false },
    { name: 'AK Öffentlichkeit', selected: false },
    { name: 'Spiri AK', selected: false },
    { name: 'AG Thema', selected: false },
    { name: 'AG Verpflegung', selected: false },
    { name: 'AG Cafe', selected: false },
    { name: 'AG Kommunikation', selected: false },
    { name: 'AG Material', selected: false },
    { name: 'AG Helfende', selected: false },
    { name: 'keine AG / kein AK', selected: false },
  ]

  wunschbereichOptions = [
    { name: 'AK GO', selected: false },
    { name: 'Rover AK', selected: false },
    { name: 'Pfadi AK', selected: false },
    { name: 'Jufi AK', selected: false },
    { name: 'Wö AK', selected: false },
    { name: 'AK Öffentlichkeit', selected: false },
    { name: 'Spiri AK', selected: false },
    { name: 'AG Thema', selected: false },
    { name: 'AG Verpflegung', selected: false },
    { name: 'AG Cafe', selected: false },
    { name: 'AG Material', selected: false },
    { name: 'AG Helfende', selected: false },
    { name: 'Lagerbüro', selected: false },
    { name: 'Kinderbetreuung ', selected: false },
    { name: 'Spül Team', selected: false },
    { name: 'mir egal', selected: false },
  ]

  tageOptions = [
    { name: '07.08.2023 – Montag, Aufbau', selected: false },
    { name: '08.08.2023 – Dienstag, Aufbau', selected: false },
    { name: '09.08.2023 – Mitwoch, Aufbau', selected: false },
    { name: '10.08.2023 – Donnerstag, Aufbau', selected: false },
    { name: '11.08.2023 – Freitag, Aufbau', selected: false },
    { name: '12.08.2023 – Samstag, Lager', selected: false },
    { name: '13.08.2023 – Sonntag, Lager', selected: false },
    { name: '14.08.2023 – Montag, Lager', selected: false },
    { name: '15.08.2023 – Dienstag, Lager', selected: false },
    { name: '16.08.2023 – Mittwoch, Lager', selected: false },
    { name: '17.08.2023 – Donnerstag, Lager', selected: false },
    { name: '18.08.2023 – Freitag, Lager', selected: false },
    { name: '19.08.2023 – Samstag, Lager', selected: false },
    { name: '20.08.2023 – Sonntag, Abbau', selected: false },
    { name: '21.08.2023 – Montag, Abbau', selected: false },
    { name: '22.08.2023 – Dienstag, Abbau', selected: false },
  ]

  constructor(
    private registrationService: RegistrationService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
    private tydalService: TydalService
  ) { }

  addHelfende(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.id = this.subscription_id
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.subscription.data.messenger = JSON.stringify(this.messengerOptions)
    this.subscription.data.akag = JSON.stringify(this.akagOptions)
    this.subscription.data.tage = JSON.stringify(this.tageOptions)
    this.subscription.data.finalized = 0
    if (this.subscription_id == undefined) {
      this.tydalService.addHelfende(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifyInfo("Anmeldung gespeichert - zur finalen Anmeldung 'Absenden' klicken")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addHelfende'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);

        },
        error => {
          this.notifyService.snotifyError("Beim Anlegen des Helfenden ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
    }
    else {
      this.tydalService.updateHelfende(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifyInfo("Anmeldung gespeichert - zur finalen Anmeldung 'Absenden' klicken")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addHelfende'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
          },

        error => {
          this.notifyService.snotifyError("Beim Speichern des Helfenden ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
    }
  }

  finalizeHelfende(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.id = this.subscription_id
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.subscription.data.messenger = JSON.stringify(this.messengerOptions)
    this.subscription.data.akag = JSON.stringify(this.akagOptions)
    this.subscription.data.tage = JSON.stringify(this.tageOptions)
    this.subscription.data.finalized = 1
    this.tydalService.updateHelfende(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung abgeschickt - ab jetzt sind keine Änderungen mehr möglich!")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addHelfende'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
          },

        error => {
          this.notifyService.snotifyError("Beim Absenden der Anmeldung ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
  }

  updateCheckedlebensmUnvert(option, event) {
    for (var i = 0; i < this.lebensmUnvertOptions.length; i++){
      if (this.lebensmUnvertOptions[i].name == option){
        this.lebensmUnvertOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedlebensmVerp(option, event) {
    for (var i = 0; i < this.lebensmVerpfOptions.length; i++){
      if (this.lebensmVerpfOptions[i].name == option){
        this.lebensmVerpfOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedakag(option, event) {
    for (var i = 0; i < this.akagOptions.length; i++){
      if (this.akagOptions[i].name == option){
        this.akagOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedtage(option, event) {
    for (var i = 0; i < this.tageOptions.length; i++){
      if (this.tageOptions[i].name == option){
        this.tageOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedmessenger(option, event) {
    for (var i = 0; i < this.messengerOptions.length; i++){
      if (this.messengerOptions[i].name == option){
        this.messengerOptions[i].selected = event.target.checked;
      }
    }
  }
  ngOnInit() { 

    this.subscription = new Subscription;

        this.event = Array()
            this.event.title = "Diözesansommerlager 2023";
            this.event.imageLink = "images/tydal_2023.PNG";
            this.event.active = 1;

            if(this.event.active == 0) {
              this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
              this.router.navigate(['/events/custom/tydal/registrations']);
            }
  
   
        this.user = this.userService.getActualUser().subscribe(
          user => {
          this.user = user;
          this.user.id = this.user['id'];
           this.user.surname = this.user['surname'];
           this.user.givenname = this.user['givenname'];
           this.user.email = this.user['email'];
           this.user.stamm = this.user['stamm'];

           this.subscription = this.tydalService.getHelfende().subscribe(
            data => {
              this.subscription = data;
              this.subscription_id = this.subscription['id'];
              this.lebensmUnvertOptions = JSON.parse(data['lebensmUnvert'])
              this.lebensmVerpfOptions = JSON.parse(data['lebensmVerpf'])
              this.messengerOptions = JSON.parse(data['messenger'])
              this.akagOptions = JSON.parse(data['akag'])
              this.tageOptions = JSON.parse(data['tage'])
    
            },
            error => {
              this.subscription.finalized = 0
              this.subscription.id = 0
            }
          );

        });
  }

}

