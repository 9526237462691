<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Neue Anmeldung erstellen</h1>
  </div>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="createRegistration(createRegistrationForm.value)" #createRegistrationForm="ngForm">
      <fieldset>
        <legend></legend>
        <div class="form-group">
          <label for="title" class="col-lg-2 control-label">Anmeldungsname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="title" name="title" placeholder="Anmeldungsname" ngModel #title="ngModel" required>
            <div *ngIf="title.errors?.required && title.dirty" class="alert alert-danger">
              Anmeldungsname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Beschreibung</label>
          <div class="col-lg-10">
            <textarea class="form-control" rows="3" id="description" name="description" ngModel #description="ngModel"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="template" class="col-lg-2 control-label">Vorlage</label>
          <div class="col-lg-10">
            <select class="form-control" id="template" name="template" [ngModel]=null ngModel #template="ngModel" required>
              <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
              <option *ngFor="let template of templates | async" [value]="template.id">{{template.title}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Status</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="active" name="active" [ngModel]=null ngModel #active="ngModel" required>
              <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
              <option [value]="1">Aktiv</option>
              <option [value]="0">Inaktiv</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Anmeldeschluss</label>
          <div class="col-lg-10">
            <input type="date" class="form-control" id="closingDate" name="closingDate" placeholder="Anmeldeschluss" ngModel #closingDate="ngModel" required>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/event/', event_id, 'registrations']" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="createRegistrationForm.invalid">Erstellen</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
