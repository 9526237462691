<div class="container">
    <div class="jumbotron row">
        <div class="display-4 col-md-10">
          <h1 class="display-4">Neue Anmeldung</h1>
          <h2 class="display-6">{{"Leitende" | uppercase}} | {{event.title | uppercase}}</h2>
        </div>
        <img class="col-md-2" src="assets/{{event.imageLink}}" alt="">
      </div>
    </div>
      <div class="col-md-10 col-md-offset-1">
        <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
        <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
        <div class="alert alert-info">
          Der Beitrag für Teilnehmende und Leiter*innen beträgt 195€. Für Anmeldungen bis zum 31.01.23 gibt es 20€ Rabatt, der Beitrag beträgt dann also 175€. Ab dem 01.03.23 erfolgt ein Aufschlag von 50€, in dieser Phase beträgt der Beitrag also 245€. Für Anmeldungen, die nach dem Anmeldeschluss am 31.05.23 eingehen, können wir nicht garantieren, diese zu berücksichtigen - nach Anmeldeschluss beträgt die Teilnahmegebühr in jedem Fall 275€. Für Kinder in der Kinderbetreuung fallen keine Beiträge an.<br />
          <br />
        Vielleicht wird dein Stamm vor oder nach dem Diözesansommerlager noch ein Vor- oder Nachlager planen. Hierfür entstehen dann vermutlich zusätzliche Kosten.<br />
        </div>
        <div class="alert alert-warning" *ngIf="subscription.finalized == 1">
          Deine Anmeldung ist erfolgreich abgeschickt - Du kannst keine Änderungen mehr vornehmen!
        </div>
        <div class="well well bs-component">
          <form novalidate class="form-horizontal" (ngSubmit)="addOrUpdateLeader(addLeaderForm.value)" #addLeaderForm="ngForm">
            <fieldset [disabled]="subscription.finalized == 1">
              <div class="form-group">
                <label for="stufe" class="col-lg-10 control-label">Stufe</label>
                <div class="alert alert-light">
                  Wir bieten für Kinder von Helfenden eine Kinderbetreuung an, sodass das Helfen gut gelingen kann. Diese Kinder sollen ebenfalls einzeln angemeldet werden. Die Anmeldung erfolgt separat über die Teilnehmenden-Anmeldung mit der Option "Kinderbetreuung".
                </div>
                <div class="col-lg-10">
                  <select class="form-control" rows="2" id="stufe" name="stufe" [ngModel]=null [(ngModel)]="subscription.stufe" #stufe="ngModel" required>
                    <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                    <option value="woelflinge">Wölflinge</option>
                    <option value="jungpfadfinder">Jungpfadfinder</option>
                    <option value="pfadfinder">Pfadfinder</option>
                    <option value="rover">Rover</option>
                  </select>
                </div>
              </div>  
              <div class="card mt-4">
                <div class="card-header">Bezahlung</div>
                <div class="card-body">
                  <div class="alert alert-light">
                    Für Stammesmitglieder erfolgt die Bezahlung der Teilnahmegebühr über den Stamm. Bitte überweise spätestens 30 Tage nachdem du eine Anmeldebestätigung erhalten hast deinen Teilnahmebeitrag.<br/>
                    Dein Stamm macht ein Vor- oder Nachlager: In dem Fall kann das Geld womöglich für beide Lager zusammen überwiesen werden. Weitere Infos dazu gibt es von deinem Stamm.
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">Kontoinhaber*in</h6>
                      </div>
                      <p class="mb-1">{{kontodaten?.kontoinhaber}}</p>
                    </li>
                    <li class="list-group-item flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">IBAN</h6>
                      </div>
                      <p class="mb-1">{{kontodaten?.iban}}</p>
                    </li>
                    <li class="list-group-item flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">BIC</h6>
                      </div>
                      <p class="mb-1">{{kontodaten?.bic}}</p>
                    </li>
                  </ul>    
                </div>
              </div>
              <div class="card mt-4">
                <div class="card-header">Lebensmittelabfragen</div>
                <div class="card-body">
            <div class="form-group">
              <div class="alert alert-light">
                Wir wollen unser Lager möglichst nachhaltig gestalten, weswegen wir nur wenig Fleisch kaufen und essen werden. Insofern entspricht die Option "Ich würde mich freuen, auch mal Fleisch zu essen." also keiner Fleischbestellung. Lebensmittelwünsche müssen korrekt angegeben werden, da wir sonst auf dem Lager nicht je genügend Portionen bereithalten können, ohne am Ende viel wegschmeißen zu müssen.              
              </div>
                <label for="lebensmVerpf" class="col-lg-12 control-label">Standardmäßig ist die Verpflegung vegetarisch.</label>
                <div class="col-lg-10 row" *ngFor="let lebensmVerpfOption of lebensmVerpfOptions">
                    <div class="col-1">
                    <input type="checkbox" id="lebensmVerpfOptions" name="lebensmVerpf" value="{{lebensmVerpfOption.name}}" [checked]="lebensmVerpfOption.selected == true" (change)="updateCheckedlebensmVerp(lebensmVerpfOption.name, $event)">
                    </div>
                    <div class="col-10">
                    {{lebensmVerpfOption.name}}
                    </div>
                </div>
                </div>
                  <div class="form-group">
                    <label for="lebensmUnvert" class="col-lg-12 control-label">Lebensmittelunverträglichkeiten</label>
                      <div class="col-lg-10 row" *ngFor="let lebensmUnvertOption of lebensmUnvertOptions">
                        <div class="col-1">
                          <input type="checkbox" id="lebensmUnvertOptions" name="lebensmUnvert" value="{{lebensmUnvertOption.name}}" [checked]="lebensmUnvertOption.selected == true" (change)="updateCheckedlebensmUnvert(lebensmUnvertOption.name, $event)">
                          </div>
                          <div class="col-10">
                          {{lebensmUnvertOption.name}}
                          </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="lebensmSonsUnvert" class="col-lg-12 control-label">Sonstige Lebensmittelunverträglichkeiten</label>
                    <div class="col-lg-10">
                      <input type="text" class="form-control" id="lebensmSonsUnvert" name="lebensmSonsUnvert" placeholder="Sonstige Unverträglichkeiten" [(ngModel)]="subscription.lebensmSonsUnvert" #lebensmSonsUnvert="ngModel">
                      <div *ngIf="lebensmSonsUnvert.errors?.required && lebensmSonsUnvert.dirty" class="alert alert-danger">
                        Sonstige Lebensmittelunverträglichkeiten ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="card mt-4">
                <div class="card-header">Medizinische Angaben</div>
                <div class="card-body">
                <div class="form-group">
                    <label for="krankheiten" class="col-lg-12 control-label">Nennenswerte Krankheiten</label>
                    <div class="alert alert-light">
                      Auf unserem Lager wird ein Sanitätsteam für uns da sein. Damit wir uns bestmöglich um dich kümmern können, bitten wir dich, hier alle dafür wichtigen Krankheiten aufzuzählen.
                    </div>
                    <div class="col-lg-10">
                            <input type="text" class="form-control" id="krankheiten" name="krankheiten" placeholder="Krankheiten" [(ngModel)]="subscription.krankheiten" #krankheiten="ngModel">
                    </div>
                </div>
                <div class="form-group">
                    <label for="medikamente" class="col-lg-12 control-label">Regelmäßige Medikamente</label>
                    <div class="alert alert-light">
                      Bitte gib hier an, ob du regelmäßig Medikamente nehmen musst und ob diese Kühlung benötigen. Besprich am besten auch mit Mit-Leitenden deines Stamms deinen Medikamentenplan.
                    </div>
                    <div class="col-lg-10">
                            <input type="text" class="form-control" id="medikamente" name="medikamente" placeholder="Medikamente" [(ngModel)]="subscription.medikamente" #medikamente="ngModel">
                    </div>
                </div>
                <div class="form-group">
                    <label for="allergien" class="col-lg-12 control-label">Allergien</label>
                    <div class="col-lg-10">
                            <input type="text" class="form-control" id="allergien" name="allergien" placeholder="Allergien" [(ngModel)]="subscription.allergien" #allergien="ngModel">
                    </div>
                </div>
                <div class="form-group">
                  <label for="infektionsschutz" class="col-lg-12 control-label"></label>
                  <div class="col-lg-10 row">
                    <div class="col-1">
                      <input type="checkbox" id="infektionsschutz" name="infektionsschutz" [ngModel]=null [(ngModel)]="subscription.infektionsschutz" #infektionsschutz="ngModel" [value]="infektionsschutz" [required]="infektionsschutz.value != true">
                    </div>
                    <div class="col-10">
                      Ich habe das <a href="https://www.rki.de/DE/Content/Infekt/IfSG/Belehrungsbogen/belehrungsbogen_eltern_deutsch.pdf?__blob=publicationFile">Merkblatt zum Infektionsschutz</a> zur Kenntnis genommen.<br />
                    </div>
        
                    <div *ngIf="infektionsschutz.errors?.required && infektionsschutz.dirty" class="alert alert-danger col-lg-12">
                      Infektionsschutz ist ein Pflichtfeld
                    </div>
                  </div>
                </div>
                </div>
              </div>
    
              <div class="card mt-4">
                <div class="card-header">Notfallkontakt</div>
                <div class="card-body">
                  <div class="alert alert-light col-lg-10">
                    Trage hier den Namen und die Telefonnummer der Person ein, die wir bei einem Notfall erreichen können.
                  </div>
                  <div class="form-group">
                    <label for="notfall_name" class="col-lg-10 control-label">Name</label>
                    <div class="col-lg-10">
                      <input type="text" class="form-control" id="notfall_name" name="notfall_name" placeholder="Name" [(ngModel)]="subscription.notfall_name" #notfall_name="ngModel" required>
                      <div *ngIf="notfall_name.errors?.required && notfall_name.dirty" class="alert alert-danger">
                        Name ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="notfall_telefonnummer" class="col-lg-10 control-label">Telefonnummer</label>
                    <div class="col-lg-10">
                      <input type="text" class="form-control" id="notfall_telefonnummer" name="notfall_telefonnummer" placeholder="Telefonnummer" [(ngModel)]="subscription.notfall_telefonnummer" #notfall_telefonnummer="ngModel" required>
                      <div *ngIf="notfall_telefonnummer.errors?.required && notfall_telefonnummer.dirty" class="alert alert-danger">
                        Telefonnummer ist ein Pflichtfeld
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-4">
                <div class="card-header">Veröffentlichungen</div>
                <div class="card-body">
                <div class="form-group">
                <div class="form-group">
                    <label for="pr" class="col-lg-10 control-label">"Es wird zugestimmt, dass von der angemeldeten Person Aufnahmen erstellt und der DPSG Hamburg unentgeltlich für die je untengenannten Zwecke zur Verfügung gestellt werden:</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="pr" name="pr" [ngModel]="null" [(ngModel)]="subscription.pr" #pr="ngModel" required>
                            <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </div>
                </div>
                <hr />
                <div class="form-group">
                  <label for="pr_intern" class="col-lg-10 control-label">Interne Veröffentlichung bspw. als Download-Link für Teilnehmende der Veranstaltungen für private Zwecke</label>
                  <div class="col-lg-10">
                      <select class="form-control" rows="2" id="pr_intern" name="pr_intern" [ngModel]="null" [(ngModel)]="subscription.pr_intern" #pr_intern="ngModel" required>
                          <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                          <option value="1">Ja</option>
                          <option value="0">Nein</option>
                      </select>
                  </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="pr_presse" class="col-lg-10 control-label">Veröffentlichungen in Medien (bspw. Pressefotos)</label>
                <div class="col-lg-10">
                    <select class="form-control" rows="2" id="pr_presse" name="pr_presse" [ngModel]="null" [(ngModel)]="subscription.pr_presse" #pr_presse="ngModel" required>
                        <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                        <option value="1">Ja</option>
                        <option value="0">Nein</option>
                    </select>
                </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="pr_internet" class="col-lg-10 control-label">Veröffentlichungen im Internet (auf den Homepages dpsg-hamburg.de und operation-kronos.de der DPSG Hamburg)</label>
              <div class="alert alert-light">
                Die angemeldete Person stimmt der Nutzung von Fotos und Filmen auch bei Ergänzung, Bearbeitung oder Entfernung von Bildbestandteilen zu. Die DPSG Hamburg verpflichtet sich, keine entstellenden oder unangemessenen Bilder von der Person zu veröffentlichen, sondern diese zu löschen. Die Zustimmung zur Veröffentlichung einzelner oder aller Aufnahmen kann jederzeit widerrufen werden. Die Veröffentlichungen werden - sofern möglich (bspw. nicht bei bereits veröffentlichten Printprodukten) - auf Anfrage umgehend gelöscht.
              </div>
              <div class="col-lg-10">
                  <select class="form-control" rows="2" id="pr_internet" name="pr_internet" [ngModel]="null" [(ngModel)]="subscription.pr_internet" #pr_internet="ngModel" required>
                      <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                      <option value="1">Ja</option>
                      <option value="0">Nein</option>
                  </select>
              </div>
          </div>
          <hr />
          <div class="form-group">
            <label for="pr_social" class="col-lg-10 control-label">Veröffentlichungen im Internet (in den sozialen Medien, insbesondere Facebook und Instagram)</label>
            <div class="alert alert-light">
              Die angemeldete Person stimmt der Nutzung von Fotos und Filmen auch bei Ergänzung, Bearbeitung oder Entfernung von Bildbestandteilen zu. Die DPSG Hamburg verpflichtet sich, keine entstellenden oder unangemessenen Bilder von der Person zu veröffentlichen, sondern diese zu löschen. Die Zustimmung zur Veröffentlichung einzelner oder aller Aufnahmen kann jederzeit widerrufen werden. Die Veröffentlichungen werden - sofern möglich (bspw. nicht bei bereits veröffentlichten Printprodukten) - auf Anfrage umgehend gelöscht.
            </div>
            <div class="col-lg-10">
                <select class="form-control" rows="2" id="pr_social" name="pr_social" [ngModel]="null" [(ngModel)]="subscription.pr_social" #pr_social="ngModel" required>
                    <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                    <option value="1">Ja</option>
                    <option value="0">Nein</option>
                </select>
            </div>
        </div>
              </div>
              </div>
              </div>
              <div class="card mt-4">
                <div class="card-header">Sonstige Angaben</div>
                <div class="card-body">

                  <div class="form-group">
                      <label for="foerderung" class="col-lg-12 control-label">Ich stimme der Nutzung und Weitergabe der hier angegebenen Daten zum Zwecke der finanziellen Förderung der Veranstaltung an berechtigte Dritte zu. Das sind insbesondere die Stadt Hamburg bzw. die für den Wohnort zuständige Sozialbehörde, das Bonifatiuswerk sowie die Landesarbeitsgemeinschaften des BDKJ in Hamburg und Schleswig-Holstein</label>
                      <div class="col-lg-10">
                          <select class="form-control" rows="2" id="foerderung" name="foerderung" [ngModel]="null" [(ngModel)]="subscription.foerderung" #foerderung="ngModel" required>
                              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                              <option value="1">Ja</option>
                              <option value="0">Nein</option>
                          </select>
                      </div>
                  </div>
                  <hr />
                <div class="form-group">
                    <label for="dokumente" class="col-lg-10 control-label">Ich bestätige, dass zu Beginn des Diözesansommerlagers alle notwendigen Dokumente (Einsicht ins erweiterte Führungszeugnis, Selbstverpflichtungserklärung, Selbstauskunftserklärung, Zertifikat über den Besuch einer Präventionsschulung vor nicht mehr als fünf Jahren) zur Prävention sexualisierter Gewalt vorliegen</label>
                    <div class="col-lg-10">
                        <select class="form-control" rows="2" id="dokumente" name="dokumente" [ngModel]="null" [(ngModel)]="subscription.dokumente" #dokumente="ngModel" required>
                        <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
                        <option value="stamm">in meinem Stamm.</option>
                        <option value="dv">im Diözesanverband.</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="sonstiges" class="col-lg-12 control-label">Gibt es noch Anmerkungen, die du an dieser Stelle loswerden möchtest?
    
                    </label>
                    <div class="col-lg-10">
                      <textarea class="form-control" id="sonstiges" name="sonstiges" placeholder="Anmerkungen" [(ngModel)]="subscription.sonstiges" #sonstiges="ngModel"></textarea>
                    </div>
                  </div>
              </div>
              </div>

              <div class="container">
                <div class="form-group">
                  <label for="schutzkonzept" class="col-lg-12 control-label"></label>
                  <div class="col-lg-10 row">
                    <div class="col-1">
                      <input type="checkbox" id="schutzkonzept" name="schutzkonzept" [ngModel]=null [(ngModel)]="subscription.schutzkonzept" #schutzkonzept="ngModel" [value]="schutzkonzept" [required]="schutzkonzept.value != true">
                    </div>
                    <div class="col-10">
                      Ich habe das <a href="http://www.dpsg-hamburg.de/themen/praevention/downloads/schutzkonzept-stand-14-07-2021/download" targe="_blank" >institutionelle Schutzkonzept</a> zur Prävention sexualisierter Gewalt der DPSG Hamburg gelesen und bestätige, mich an dieses und besonders an den enthaltenen Verhaltenskodex zu halten.<br />
                    </div>
        
                    <div *ngIf="schutzkonzept.errors?.required && schutzkonzept.dirty" class="alert alert-danger col-lg-12">
                      Schutzkonzept ist ein Pflichtfeld
                    </div>
                  </div>
                </div>
          </div>

          <div class="container">
            <div class="form-group">
              <label for="agb" class="col-lg-12 control-label"></label>
              <div class="col-lg-10 row">
                <div class="col-1">
                  <input type="checkbox" id="agb" name="agb" [ngModel]=null [(ngModel)]="subscription.agb" #agb="ngModel" [value]="agb" [required]="agb.value != true">
                </div>
                <div class="col-10">
                  Ich bestätige die <a href="/assets/docs/tydal/AGB.pdf" target="_blank">AGB</a> und habe die <a href="/assets/docs/tydal/Datenschutzerklaerung_KDG.pdf" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen<br />
                </div>
                <div *ngIf="agb.errors?.required && agb.dirty" class="alert alert-danger col-lg-12">
                  AGB ist ein Pflichtfeld
                </div>
              </div>
            </div>
      </div>

            </fieldset>
            <div class="form-group">
              <div class="col-lg-10 col-lg-offset-2">
                <a [routerLink]="['/events/custom/tydal/registrations']" class="btn btn-outline-secondary col-12 col-sm-3">Abbrechen</a>
                <button class="btn btn-primary my-3 my-sm-0 mx-sm-5 col-12 col-sm-3" [disabled]="addLeaderForm.invalid || subscription.finalized == 1">Speichern</button>
                <button (click)="finalizeLeader(addLeaderForm.value)" class="btn btn-danger col-12 col-sm-3" [disabled]="addLeaderForm.invalid || subscription.id == 0 || subscription.finalized == 1">Absenden</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      