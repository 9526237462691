import { Injectable } from '@angular/core';
import {SnotifyService, SnotifyPosition, SnotifyToastConfig} from 'ng-snotify';

@Injectable()
export class NotifyService {

timeout = 3000;
position: SnotifyPosition = SnotifyPosition.rightBottom;
progressBar = false;
closeClick = true;
newTop = true;
backdrop = -1;
dockMax = 8;
blockMax = 6;
pauseHover = true;
titleMaxLength = 15;
bodyMaxLength = 80;

  constructor(
    private snotifyService: SnotifyService
  ) { }

  private getConfig(): SnotifyToastConfig {
      this.snotifyService.setDefaults({
        global: {
          newOnTop: this.newTop,
          maxAtPosition: this.blockMax,
          maxOnScreen: this.dockMax,
        }
      });
      return {
        bodyMaxLength: this.bodyMaxLength,
        titleMaxLength: this.titleMaxLength,
        backdrop: this.backdrop,
        position: this.position,
        timeout: this.timeout,
        showProgressBar: this.progressBar,
        closeOnClick: this.closeClick,
        pauseOnHover: this.pauseHover
      };
    }

  snotifySuccess(message:string) {
    this.snotifyService.success(message, this.getConfig());
  }

  snotifyInfo(message:string) {
    this.snotifyService.info(message, this.getConfig());
  }

  snotifyError(message:string) {
    this.snotifyService.error(message, this.getConfig());
  }

}
