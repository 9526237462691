import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Event } from '../../../models/event';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'app-admin-event-list',
  templateUrl: './admin-event-list.component.html',
  styleUrls: ['./admin-event-list.component.css']
})
export class AdminEventListComponent implements OnInit {
events: Observable<Event[]>;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.events = this.eventService.getEvents();
  }

}
