import { Component, OnInit, Input, Pipe} from '@angular/core';
import { Event } from '../../../../models/event';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../../services/registration.service';
import { Registration } from '../../../../models/registration';
import { EventService } from '../../../../services/event.service';
import { StammService } from '../../../../services/stamm.service';
import { TemplatePageService } from '../../../../services/template-page.service';
import { UserService } from '../../../../services/user.service';
import {Observable} from 'rxjs';
import { User } from '../../../../models/user';
import { Router} from '@angular/router';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ExportService } from '../../../../services/export.service';
import { TydalService } from '../../../../services/tydal.service';

@Component({
  selector: 'app-tydal-du2023-list',
  templateUrl: './tydal-du2023-list.component.html',
  styleUrls: ['./tydal-du2023-list.component.css']
})
export class TydalDu2023ListComponent implements OnInit {
  
  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  switch: any;
  leaders: any;
  tns: any;
  sum: any;
  totalofLeader: any;
  totalofTns: any;
  totalofAnmeldenden: any;
  totalofHelfende: any;
  filter: any;
  helfende: any;
  staves: any;
  exportData: any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private exportService: ExportService,
    private registrationService: RegistrationService,
    private stammService: StammService,
    private tydalService: TydalService,
    private templatePageService: TemplatePageService,
    private userService: UserService,
    private router: Router
  ) { }

  export(what) {
    switch(what) {
      case "tns": {
        this.tns.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
 
      case "leader": {
        this.leaders.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
 
      case "helfende": {
        this.helfende.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
        break;
      }
    }
 
  }

  updateSwitch(event) {
    this.switch = event.target.id;
    }

  ngOnInit(): void {
    this.switch = "tns";
    this.sum = 0;
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);

    this.event = new Array()
    this.event.title = "Diözesansommerlager 2023"
    this.event.imageLink = "assets/images/tydal_2023.PNG";
    this.event.active = 1;

    this.leaders = this.tydalService.getAllLeader()
    this.leaders.subscribe(

      result => {
        this.totalofLeader = result.length
      }
    )

    this.tns = this.tydalService.getAllTN()

    this.tns.subscribe(
      result => {
        this.totalofTns = result.length
      }
    )

    this.helfende = this.tydalService.getAllHelfende();
    this.helfende.subscribe(
      result => {
        this.totalofHelfende = result.length
      }
    )
  }

}