import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '../../../services/event.service';
import { Event } from '../../../models/event';
import { Router} from '@angular/router';


@Component({
  selector: 'app-admin-event-edit',
  templateUrl: './admin-event-edit.component.html',
  styleUrls: ['./admin-event-edit.component.css']
})
export class AdminEventEditComponent implements OnInit {

  params: any;
  event_id: any;
  event: any;
  status: any;
  message: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['id']);
   this.event = this.eventService.getEvent(this.event_id).subscribe(
     data => {
       this.event.description = data['description'];
       this.event.title = data['title'];
       this.event.imageLink = data['imageLink'];
       this.event.id = data['id'];
       this.event.active = data['active'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateEvent(event) {
   this.eventService.updateEvent(event)
   .subscribe(
     event => {
       this.status = 'success';
       this.message = event['message'];
       this.router.navigate(['/admin/events']);

     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }


}
