<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Benutzer bearbeiten</h1>
  </div>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="updateUser(editUserForm.value)" #editUserForm="ngForm">
      <fieldset>
        <div class="form-group">
          <label for="surname" class="col-lg-3 control-label">Geschlecht</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="gender" name="gender" [(ngModel)]="user.gender" ngModel #gender="ngModel" required>
              <option [value]=null disabled hidden>Bitte auswählen</option>
              <option [selected]="user.gender == 'd'" value="d">divers</option>
              <option [selected]="user.gender == 'w'" value="w">weiblich</option>
              <option [selected]="user.gender == 'm'" value="m">männlich</option>
            </select>
            <div *ngIf="gender.errors?.required && gender.dirty" class="alert alert-danger">
              Geschlecht ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="surname" class="col-lg-3 control-label">Vorname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="surname" name="surname" placeholder="Vorname" [(ngModel)]="user.surname" #surname="ngModel" required>
            <div *ngIf="surname.errors?.required && surname.dirty" class="alert alert-danger">
              Vorname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="givenname" class="col-lg-3 control-label">Nachname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="givenname" name="givenname" placeholder="Nachname" [(ngModel)]="user.givenname" #givenname="ngModel" required>
            <div *ngIf="givenname.errors?.required && givenname.dirty" class="alert alert-danger">
              Nachname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="fahrtenname" class="col-lg-3 control-label">Ruf-/Fahrtenname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="fahrtenname" name="fahrtenname" placeholder="Ruf-/Fahrtenname" [(ngModel)]="user.fahrtenname" #fahrtenname="ngModel">
          </div>
        </div>
        <div class="form-group">
          <label for="pronomen" class="col-lg-3 control-label">Pronomen</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="pronomen" name="pronomen" placeholder="Pronomen" [(ngModel)]="user.pronomen" #pronomen="ngModel">
          </div>
        </div>
        <div class="form-group">
          <label for="email" class="col-lg-3 control-label">E-Mail-Adresse</label>
          <div class="col-lg-10">
            <input type="email" class="form-control" id="email" name="email" placeholder="E-Mail-Adresse" [(ngModel)]="user.email" #email="ngModel" required>
            <div *ngIf="email.errors?.required && email.dirty" class="alert alert-danger">
              E-Mail-Adresse ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="strasse" class="col-lg-3 control-label">Straße</label>
          <div class="col-lg-10">
            <input type="strasse" class="form-control" id="strasse" name="strasse" placeholder="Straße" [(ngModel)]="user.strasse" #strasse="ngModel" required>
            <div *ngIf="strasse.errors?.required && strasse.dirty" class="alert alert-danger">
              Strasse ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="hausnummer" class="col-lg-3 control-label">Hausnummer</label>
          <div class="col-lg-10">
            <input type="hausnummer" class="form-control" id="hausnummer" name="hausnummer" placeholder="Hausnummer" [(ngModel)]="user.hausnummer" #hausnummer="ngModel" required>
            <div *ngIf="hausnummer.errors?.required && hausnummer.dirty" class="alert alert-danger">
              Hausnummer ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="plz" class="col-lg-3 control-label">PLZ</label>
          <div class="col-lg-10">
            <input type="plz" class="form-control" id="plz" name="plz" placeholder="PLZ" [(ngModel)]="user.plz" #plz="ngModel" required>
            <div *ngIf="plz.errors?.required && plz.dirty" class="alert alert-danger">
              PLZ ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="ort" class="col-lg-3 control-label">Ort</label>
          <div class="col-lg-10">
            <input type="ort" class="form-control" id="ort" name="ort" placeholder="Ort" [(ngModel)]="user.ort" #ort="ngModel" required>
            <div *ngIf="ort.errors?.required && ort.dirty" class="alert alert-danger">
              Ort ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="telefonnummer" class="col-lg-3 control-label">Festnetznummer</label>
          <div class="col-lg-10">
            <input type="telefonnummer" class="form-control" id="telefonnummer" name="telefonnummer" placeholder="Telefonnummer" [(ngModel)]="user.telefonnummer" #telefonnummer="ngModel">
          </div>
        </div>
        <div class="form-group">
          <label for="handynummer" class="col-lg-3 control-label">Handynummer</label>
          <div class="col-lg-10">
            <input type="handynummer" class="form-control" id="handynummer" name="handynummer" placeholder="Handynummer" [(ngModel)]="user.handynummer" #handynummer="ngModel" required>
            <div *ngIf="handynummer.errors?.required && handynummer.dirty" class="alert alert-danger">
              Handynummer ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="geburtsdatum" class="col-lg-10 control-label">Geburtsdatum</label>
          <div class="col-lg-10">
            <input type="date" class="form-control" id="geburtsdatum" name="geburtsdatum" placeholder="Geburtsdatum" [(ngModel)]="user.geburtsdatum" #geburtsdatum="ngModel" required>
            <div *ngIf="geburtsdatum.errors?.required && geburtsdatum.dirty" class="alert alert-danger">
              Geburtsdatum ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="stamm" class="col-lg-3 control-label">Stamm</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="stamm" name="stamm" [(ngModel)]="user.stamm" ngModel #stamm="ngModel" required>
              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
              <option [selected]="user.stamm == stamm.id" *ngFor="let stamm of staemme | async" [value]="stamm.id">[{{stamm.number}}] {{stamm.title}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="stamm" class="col-lg-3 control-label">DPSG-Mitgliedschaft?</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="dpsgmember" name="dpsgmember" [(ngModel)]="user.dpsgmember" #active="ngModel" required>
              <option [value]=null [selected] disabled hidden>Bitte auswählen</option>
              <option [selected]="user.dpsgmember == 1" [value]="1">Ja</option>
              <option [selected]="user.dpsgmember == 0" [value]="0">Nein</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="admin" class="col-lg-3 control-label">Admin</label>
          <div class="col-lg-10">
              <input type="checkbox" name="admin" [(ngModel)]="user.admin" [checked]="user.admin == 1">
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/users']" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="editUserForm.invalid">Ändern</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
