import { Component, OnInit } from '@angular/core';
import {EventService} from '../../../services/event.service';
import { Router} from '@angular/router';


@Component({
  selector: 'app-admin-event-create',
  templateUrl: './admin-event-create.component.html',
  styleUrls: ['./admin-event-create.component.css']
})
export class AdminEventCreateComponent implements OnInit {
status: any;

  constructor(
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  createEvent(event) {
   this.eventService.addEvent(event)
   .subscribe(
     event => {
       this.router.navigate(['/admin/events']);
     },
     error => console.log(<any>error)
   );
 }


}
