<nav class="navbar navbar-expand-lg navbar-dark">
  <!-- Brand and toggle get grouped for better mobile display -->
  <a class="navbar-brand" href="#">DPSG Hamburg - Anmeldung</a>
<!-- TODO: Fix nav mobile view -->
  <!-- Navbar Right -->
  <div class="navbar-collapse" id="dpsgea-navbar">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown" >
          <div *ngIf="this.checkLogin==true; else login">

              <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i class="fa fa-user"></i>{{(user)?.surname}} {{(user)?.givenname}} ({{(user)?.email}})
                <span class="caret"></span></a>
                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                  <div *ngIf="this.user.admin==1">
                    <li><a class="dropdown-item" href="/admin/users"><i class="fa fa-users"></i>Benutzer verwalten</a></li>
                    <li><a class="dropdown-item" href="/admin/events"><i class="fa fa-calendar"></i>Veranstaltungen verwalten</a></li>
                    <li><a class="dropdown-item" href="/admin/templates"><i class="fa fa-wpforms"></i>Vorlagen verwalten</a></li>
                    <div class="dropdown-divider"></div>
                  </div>
                  <li><a class="dropdown-item" href="/user/changeData"><i class="fa fa-user"></i>Daten ändern</a></li>
                  <li><a class="dropdown-item" href="/user/changePassword"><i class="fa fa-key"></i>Passwort ändern</a></li>
                  <li><a class="dropdown-item" [routerLink]="[]" (click)="logout()"><i class="fa fa-sign-out"></i>Logout</a></li>
                </ul>
          </div>
          <ng-template #login>
            <a href="/login" class="nav-link" role="button">Einloggen</a>
          </ng-template>
          </li>
        </ul>
    </div>
  </nav>
