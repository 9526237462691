<div class="col-md-12 col-md-offset-1">

  <div class="row row-cols-1 row-cols-md-3 justify-content-md-center">
    <div class="col mb-4">
      <div class="card h-100">
        <p><img class="card-img-top" src="assets/images/tydal_2023.PNG" alt="Pfadi-Diözesanunternehmen"></p>
        <div class="card-footer">
          <h5 class="card-title">Diözesansommerlager 2023</h5>
          <a [routerLink]="['custom', 'tydal', 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        </div>
     </div>
    </div>

    <div class="col mb-4" *ngFor="let event of events | async" >
      <div class="card h-100">
        <p><img class="card-img-top" src="assets/{{event.imageLink}}" alt="{{event.title}}"></p>
        <div class="card-body">
          <p [innerHTML]="event.description" class="card-text"></p>
        </div>
        <div class="card-footer">
          <h5 class="card-title">{{event.title}}</h5>
          <a [routerLink]="[event.id, 'registrations']" class="btn btn-primary col-12">Anmeldung</a>
        </div>
      </div>
    </div>
</div>

</div>