import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import { User } from '../../../models/user';
import { Router} from '@angular/router';
import { StammService } from '../../../services/stamm.service';
import { Stamm } from '../../../models/stamm';
import { Bezirk } from '../../../models/bezirk';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.css']
})
export class AdminUserEditComponent implements OnInit {

  bezirkSelected: any = true;
  params: any;
  user_id: any;
  user: any;
  status: any;
  message: any;
  staemme:  Observable<Stamm[]>;
  bezirke:  Observable<Bezirk[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private stammService: StammService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.staemme = this.stammService.getStaemme()

    this.params = this.activatedRoute.params.subscribe(params => this.user_id = params['id']);
   this.user = this.userService.getUser(this.user_id).subscribe(
     data => {
      this.user = data;
      this.user.gender = this.user['gender'];
      this.user.geburtsdatum = this.user['geburtsdatum'];
      this.user.surname = this.user['surname'];
      this.user.givenname = this.user['givenname'];
      this.user.fahrtenname = this.user['fahrtenname'];
      this.user.email = this.user['email'];
      this.user.bezirk = this.user['bezirk'];
      this.staemme = this.stammService.getStaemme()
      this.user.stamm = this.user['stamm'];
      this.user.strasse = this.user['strasse'];
      this.user.hausnummer = this.user['hausnummer'];
      this.user.plz = this.user['plz'];
      this.user.ort = this.user['ort'];
      this.user.telefonnummer = this.user['telefonnummer'];
      this.user.dpsgmember = this.user['dpsgmember'];
      this.user_id = this.user['id'];
      this.user.admin = data['admin'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateUser(user) {
   user.id = this.user_id;
   this.userService.updateUser(user)
   .subscribe(
     user => {
       this.status = 'success';
       this.message = user['message'];
       this.router.navigate(['/admin/users']);

     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
