import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Router} from '@angular/router';
import { Subscription } from '../../../models/subscription';
import { NotifyService } from '../../../services/notify.service';
import { TydalService } from '../../../services/tydal.service';


@Component({
  selector: 'app-tydal-add-tn',
  templateUrl: './tydal-add-tn.component.html',
  styleUrls: ['./tydal-add-tn.component.css']
})
export class TydalAddTnComponent implements OnInit {

  event_id: any;
  registration_id: any;
  subscription_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templatePages: any;
  status: any;
  templatePage: any;
  templatePage_id: any;
  subscription: any;
  istGeschwister: boolean;
  kontodaten:any;

  lebensmUnvertOptions = [
    { name: 'Gluten', selected: false },
    { name: 'Eier', selected: false },
    { name: 'Fische', selected: false },
    { name: 'Erdnüsse', selected: false },
    { name: 'Sojabohnen', selected: false },
    { name: 'Laktose', selected: false },
    { name: 'Sellerie', selected: false },
    { name: 'Senf', selected: false },
    { name: 'Sesamsamen', selected: false },
    { name: 'Schwefeldioxid und Sulphite in Konzentrationen', selected: false },
    { name: 'Lupinen', selected: false},
  ]

  lebensmVerpfOptions = [
    { name: 'Ich würde gerne vegan essen.', selected: false },
    { name: 'Ich würde mich freuen, auch mal Fleisch zu essen.', selected: false },
  ]

  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
    private tydalService: TydalService
  ) { }

  addOrUpdateTN(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.id = this.subscription_id
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.subscription.data.finalized = 0
    if (this.subscription_id == undefined) {
      this.tydalService.addTN(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifyInfo("Anmeldung gespeichert - zur finalen Anmeldung 'Absenden' klicken")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addTN'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);

        },
        error => {
          this.notifyService.snotifyError("Beim Anlegen des Teilnehmenden ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
    }
    else {
      this.tydalService.updateTN(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifyInfo("Anmeldung gespeichert - zur finalen Anmeldung 'Absenden' klicken")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addTN'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
          },

        error => {
          this.notifyService.snotifyError("Beim Speichern des Teilnehmenden ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
    }
  }

  finalizeTN(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.id = this.subscription_id
    this.subscription.data = subscriptiondata
    this.subscription.data.lebensmUnvert = JSON.stringify(this.lebensmUnvertOptions)
    this.subscription.data.lebensmVerpf = JSON.stringify(this.lebensmVerpfOptions)
    this.subscription.data.finalized = 1
    this.tydalService.updateTN(this.subscription)
      .subscribe(
        subscription => {
          this.notifyService.snotifySuccess("Anmeldung abgeschickt - ab jetzt sind keine Änderungen mehr möglich!")
          setTimeout(() => {
            this.router.navigate(['/events/custom/tydal/addTN'])
              .then(() => {
                window.location.reload();
              });
            }, 3000);
          },

        error => {
          this.notifyService.snotifyError("Beim Absenden der Anmeldung ist ein Fehler aufgetreten!")
          console.log(<any>error)
        }
      );
  }

  updateCheckedlebensmUnvert(option, event) {
    for (var i = 0; i < this.lebensmUnvertOptions.length; i++){
      if (this.lebensmUnvertOptions[i].name == option){
        this.lebensmUnvertOptions[i].selected = event.target.checked;
      }
    }
  }

  updateCheckedlebensmVerp(option, event) {
    for (var i = 0; i < this.lebensmVerpfOptions.length; i++){
      if (this.lebensmVerpfOptions[i].name == option){
        this.lebensmVerpfOptions[i].selected = event.target.checked;
      }
    }
  }

  ngOnInit() {

    this.subscription = new Subscription;

        this.event = Array()
            this.event.title = "Diözesansommerlager 2023";
            this.event.imageLink = "images/tydal_2023.PNG";
            this.event.active = 1;

            if(this.event.active == 0) {
              this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
              this.router.navigate(['/events/custom/tydal/registrations']);
            }
  
       
       this.user = this.userService.getActualUser().subscribe(
          user => {
          this.user = user;
          this.user.id = this.user['id'];
           this.user.surname = this.user['surname'];
           this.user.givenname = this.user['givenname'];
           this.user.email = this.user['email'];
           this.user.stamm = this.user['stamm'];

           this.tydalService.getStammKontodaten(this.user.stamm).subscribe(
            data => {
              this.kontodaten = data
           })
          
           this.subscription = this.tydalService.getTN().subscribe(
            data => {
              this.subscription = data;
              this.subscription_id = this.subscription['id'];
              this.lebensmUnvertOptions = JSON.parse(data['lebensmUnvert'])
              this.lebensmVerpfOptions = JSON.parse(data['lebensmVerpf'])
    
            },
            error => {
              this.subscription.finalized = 0
              this.subscription.id = 0
            }
          );

        });
  }

}
