<div class="container">
    <div class="jumbotron row">
        <div class="display-4 col-md-10">
            <h1 class="display-4">ANMELDUNG</h1>
            <h2 class="display-6">{{event.title | uppercase}}</h2>
        </div>
        <img class="col-md-2" src="../assets/images/tydal_2023.PNG" alt="">
    </div>
</div>
<div class="alert alert-info">
    Es geht wieder los! Vom 12.08. - 19.08.2023 findet wieder ein Diözesanlager - also ein Lager mit allen DPSG-Pfadfindern aus Hamburg und Schleswig-Holstein - statt. Da es diesmal ein Sommerlager wird, haben wir mehr Zeit für ein fantastisches Programm. Es wird große Aktionen mit allen gemeinsam in der Diözesanzeit geben, gemeinsames Erleben in eurer Stufenzeit und individuelles Programm mit eurem Stamm in eurer Stammeszeit.<br/>
    <br/>
    Die meisten Angaben in dieser Anmeldung sind vermutlich selbsterklärend, bei vielen anderen gibt es weitere Erklärungen in Infotexten. Falls du doch noch weitere Fragen hast, dann melde dich gerne per Mail (<a href="mailto:kommunikation@sola2023.de">kommunikation@sola2023.de</a>) bei uns. Wenn du lieber live mit Menschen sprichst und bis Mittwoch warten kannst, dann sind wir von 17-19 Uhr immer unter 040 / 22 72 16 -11 für dich erreichbar. Alternativ können wir, wenn du uns schreibst, auch einen Termin ausmachen.<br/>
    <br/>
    Alle Teilnehmenden, also beispielsweise Kinder und Jugendliche aus den Stufen, melden sich über die Teilnehmenden-Anmeldung an. Leitende, die ihre Stammesteilnehmenden begleiten, nutzen die Leitendenanmeldung. Wer hingegen unabhängig von einem Stamm auf dem Diözesanlager helfen möchte, nutzt die Helfendenanmeldung.<br/>
    <br/>
    Jede Anmeldung lässt sich am Ende des Formulars "Speichern", um später zurückzukehren und sie weiter auszufüllen. Erst das "Absenden" ist jedoch die tatsächliche Anmeldung, für die es dann auch per Mail eine Bestätigung gibt.
</div>
<div class="card-deck" class="row row-cols-1 row-cols-md-3 justify-content-md-center">
    <div class="col" *ngIf="subscription == 'none'">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Teilnehmende</h5>
                <p class="card-text"></p>
                <a href="/events/custom/tydal/addTN" class="btn btn-primary col-12">Anmeldung</a>
            </div>
        </div>
    </div>
    <div class="col" *ngIf="subscription == 'none'">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Leitende</h5>
                <p class="card-text"></p>
                <a href="/events/custom/tydal/addLeader" class="btn btn-primary col-12">Anmeldung</a>
            </div>
        </div>
    </div>
    <div class="col" *ngIf="subscription == 'none'">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">Helfende</h5>
                <p class="card-text"></p>
                <a href="/events/custom/tydal/addHelfende" class="btn btn-primary col-12">Anmeldung</a>
            </div>
        </div>
    </div>
    <div class="col" *ngIf="subscription == 'TN' || subscription == 'Leader' || subscription == 'Helfende'">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title">zusätzliche Teilnehmende</h5>
                <p class="card-text"></p>
                <a href="/events/custom/tydal/addAdditionalTN" class="btn btn-primary col-12">Anmeldung</a>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive mt-3">
    <table class="table table-users">
      <thead>
        <tr>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Typ</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{user.surname}}</td>
          <td>{{user.givenname}}</td>
          <td>{{subscription}}</td>
          <td>
            <a [routerLink]="['/events', 'custom', 'tydal', 'addTN']" class="btn btn-warning btn-users" *ngIf="subscription=='TN'"> Bearbeiten</a>
            <a [routerLink]="['/events', 'custom', 'tydal', 'addLeader']" class="btn btn-warning btn-users" *ngIf="subscription=='Leader'"> Bearbeiten</a>
            <a [routerLink]="['/events', 'custom', 'tydal', 'addHelfende']" class="btn btn-warning btn-users" *ngIf="subscription=='Helfende'"> Bearbeiten</a>
         </td>
        </tr>
        <tr *ngFor="let additionalTN of additionalTNs | async">
            <td>{{additionalTN.surname}}</td>
            <td>{{additionalTN.givenname}}</td>
            <td>TN</td>
            <td>
              <a [routerLink]="['/events', 'custom', 'tydal', 'addAdditionalTN', additionalTN.id ]" class="btn btn-warning btn-users"> Bearbeiten</a>
           </td>
          </tr>
        
      </tbody>
    </table>
  </div>
  