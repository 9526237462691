import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { TydalService } from '../../services/tydal.service';

@Component({
  selector: 'app-tydal',
  templateUrl: './tydal.component.html',
  styleUrls: ['./tydal.component.css']
})
export class TydalComponent implements OnInit {
  event: any;
  subscription: any;
  vorname: any;
  nachname: any;
  user: any;
  additionalTNs: any;

  constructor(
    private tydalService: TydalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.event = new Array()
  this.event.title = "Diözesanunternehmen 2023"
  this.event.imageLink = "../assets/images/tydal_2023.PNG";
  this.event.active = 1;


  this.user = this.userService.getActualUser().subscribe(
    user => {
    this.user = user;
    this.user.id = this.user['id'];
     this.user.surname = this.user['surname'];
     this.user.givenname = this.user['givenname'];
     this.user.email = this.user['email'];
     this.user.stamm = this.user['stamm'];

  this.tydalService.checkSubscription().subscribe(
    typ => {
      this.subscription = typ
      switch(typ) {
        case "Helfende":
          this.tydalService.getHelfende().subscribe(
            data => {
              this.vorname = data["vorname"]
              this.nachname = data["vorname"]
            }
          )
          break;
        case "TN":
          this.tydalService.getTN().subscribe(
            data => {
              this.vorname = data["vorname"]
              this.nachname = data["vorname"]
            }
          )
          break;
        case "Leader":
          this.tydalService.getLeader().subscribe(
            data => {
              this.vorname = data["vorname"]
              this.nachname = data["vorname"]
            }
          )
          break;
      }
      this.additionalTNs = this.tydalService.getAdditionalTNofUser()
    }
  )

  })
}
}
