import {Routes, RouterModule} from '@angular/router';

import { EventsDashboardComponent } from './events-dashboard.component';
import { EventsRegistrations } from './events-registrations/events-registrations.component';
import { EventsRegistrationsSubscriptionComponent } from './events-registrations-subscription/events-registrations-subscription.component';
import { EventsRegistrationSubscriptionAddComponent } from './events-registrations-subscription/events-registration-subscription-add/events-registration-subscription-add.component';
import { EventsRegistrationSubscriptionEditComponent } from './events-registrations-subscription/events-registration-subscription-edit/events-registration-subscription-edit.component';
import { EventsRegistrationSubscriptionDelComponent } from './events-registrations-subscription/events-registration-subscription-del/events-registration-subscription-del.component';
import { TydalAddTnComponent } from './custom/tydal-add-tn/tydal-add-tn.component';
import { TydalAddLeaderComponent } from './custom/tydal-add-leader/tydal-add-leader.component';
import { TydalAddHelfendeComponent } from './custom/tydal-add-helfende/tydal-add-helfende.component';
import { AuthGuardService } from '../services/auth.guard.service';
import { TydalComponent } from './custom/tydal.component';
import { TydalAddAdditionalTNComponent } from './custom/tydal-add-additional-tn/tydal-add-additional-tn.component';


export const eventsRoutes: Routes = [
  { path: '', component: EventsDashboardComponent},
  { path: ':event_id', redirectTo:':event_id/registrations', pathMatch: 'full'},
  { path: ':event_id/registrations', component: EventsRegistrations, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription', component: EventsRegistrationsSubscriptionComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/add', component: EventsRegistrationSubscriptionAddComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/:subscription_id/edit', component: EventsRegistrationSubscriptionEditComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/:subscription_id/del', component: EventsRegistrationSubscriptionDelComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/registrations', component: TydalComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/addTN', component: TydalAddTnComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/addLeader', component: TydalAddLeaderComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/addHelfende', component: TydalAddHelfendeComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/addAdditionalTN', component: TydalAddAdditionalTNComponent, canActivate: [AuthGuardService]},
  { path: 'custom/tydal/addAdditionalTN/:subscription_id', component: TydalAddAdditionalTNComponent, canActivate: [AuthGuardService]},
];
