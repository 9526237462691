<div class="jumbotron">
  <h1 class="display-4">Vorlage: {{template.title}}</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/templates']" class="btn btn-secondary">Zurück</a>
    <a [routerLink]="['/admin/template', template_id, 'page', 'create']" class="btn btn-success">Neue Seite anlegen</a>
  </div>
</div>
<br />
<div id="accordion">
  <div class="card" *ngFor="let templatePage of templatePages | async">
    <div class="card-header" id="heading{{templatePage.id}}">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse'+ templatePage.id" aria-expanded="true" [attr.aria-controls]="'collapse' + templatePage.id">
            Seite: {{templatePage.title}}
        </button>
        <div class="float-right">
          <a [routerLink]="['/admin/template', template_id, 'page', 'edit', templatePage.id]" class="btn btn-warning"> Bearbeiten</a>
          <a [routerLink]="['/admin/template', template_id, 'page', 'delete', templatePage.id]" class="btn btn-danger"> Löschen</a>
        </div>
      </h5>
    </div>

    <div [attr.id]="'collapse'+templatePage.id" class="collapse" [attr.aria-labelledby]="'heading'+templatePage.id" data-parent="#accordion">
      <div class="card-body">
        <app-admin-template-fields [templatePage]="templatePage.id"></app-admin-template-fields>
      </div>
    </div>
  </div>
</div>
