import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../models/event';
import {ActivatedRoute} from '@angular/router';
import { Router } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';
import { Registration } from '../../models/registration';
import { EventService } from '../../services/event.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-events-registrations',
  templateUrl: './events-registrations.component.html',
  styleUrls: ['./events-registrations.component.css']
})
export class EventsRegistrations implements OnInit {

 event_id: any;
 params: any;
 registrations:  Observable<Registration[]>;
 event:  any;
 progress: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.registrations = this.registrationService.getActiveRegistrations(this.event_id);
    this.registrations.subscribe(result => {
      if(result.length == 1)
      {
        this.router.navigate(['events', this.event_id, 'registrations', result[0].id, 'subscription']);
      }
    });
    this.event = this.eventService.getEvent(this.event_id).subscribe(
      data => {
        this.event.title = data['title'];
        this.event.imageLink = data['imageLink'];
      },
      error => console.log(<any>error));

  }

}
