<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Anmeldung löschen</h1>
    <h2 class="display-6">{{registration.title | uppercase}} | {{event.title | uppercase}}</h2>
  </div>
  <img src="assets/{{event.imageLink}}" alt="">
</div>
<div class="alert alert-danger" role="alert">
Bist du dir sicher, dass du diesen Eintrag löschen möchtest?<br />
<a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription']" class="btn btn-outline-danger">Nein</a>
<button type="button" class="btn btn-danger" (click)="deleteSubscription()">Ja</button>
</div>
