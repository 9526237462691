import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { BackendService } from '../services/backend.service';
@Injectable({
  providedIn: 'root'
})
export class TydalService {

  constructor(
    private backendService: BackendService
  ) { }

  addTN(subscription: Object): Observable<any[]> {
    return this.backendService.post('events/custom/tydal/TN', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

   addAdditionalTN(subscription: Object): Observable<any[]> {
      return this.backendService.post('events/custom/tydal/AdditionalTN', subscription)
         .map((response) => response)
        .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
     }

   addLeader(subscription: Object): Observable<any[]> {
    return this.backendService.post('events/custom/tydal/Leader', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

   addHelfende(subscription: Object): Observable<any[]> {
    return this.backendService.post('events/custom/tydal/Helfende', subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

   checkSubscription(): Observable<any>{
    return this.backendService.get('events/custom/tydal/subscription')
       .map((response) => response);
  }

  getStammKontodaten(stamm_id: string): Observable<any>{
    return this.backendService.get('staemme/kontodaten/' + stamm_id)
       .map((response) => response);
  }

  getAllTN(): Observable<any> {
   return this.backendService.get('events/custom/tydal/AllTN')
      .map((response) => response);
 }

 getAllLeader(): Observable<any> {
   return this.backendService.get('events/custom/tydal/AllLeader')
      .map((response) => response);
 }

 getAllHelfende(): Observable<any> {
   return this.backendService.get('events/custom/tydal/AllHelfende')
      .map((response) => response);
 }

  getTN(): Observable<any> {
    return this.backendService.get('events/custom/tydal/TN')
       .map((response) => response);
  }
  getAdditionalTNofUser(): Observable<any> {
   return this.backendService.get('events/custom/tydal/AdditionalTNofUser')
      .map((response) => response);
 }

 getAdditionalTN(subscription_id: string): Observable<any> {
   return this.backendService.get('events/custom/tydal/AdditionalTN/' + subscription_id)
      .map((response) => response);
 }

  getLeader(): Observable<any> {
    return this.backendService.get('events/custom/tydal/Leader')
       .map((response) => response);
  }

  getHelfende(): Observable<any> {
    return this.backendService.get('events/custom/tydal/Helfende')
       .map((response) => response);
  }

  updateTN(subscription: Object): Observable<any[]> {
    const apiUrl = 'events/custom/tydal/TN';
    const url = `${apiUrl}/${subscription['id']}`;
    return this.backendService.put(url, subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

   
  updateAdditionalTN(subscription: Object): Observable<any[]> {
   const apiUrl = 'events/custom/tydal/AdditionalTN';
   const url = `${apiUrl}/${subscription['id']}`;
   return this.backendService.put(url, subscription)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

   updateLeader(subscription: Object): Observable<any[]> {
    const apiUrl = 'events/custom/tydal/Leader';
    const url = `${apiUrl}/${subscription['id']}`;
    return this.backendService.put(url, subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }

   updateHelfende(subscription: Object): Observable<any[]> {
    const apiUrl = 'events/custom/tydal/Helfende';
    const url = `${apiUrl}/${subscription['id']}`;
    return this.backendService.put(url, subscription)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
   }
}
