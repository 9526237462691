import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Event } from '../models/event';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-events-dashboard',
  templateUrl: './events-dashboard.component.html',
  styleUrls: ['./events-dashboard.component.css']
})
export class EventsDashboardComponent implements OnInit {

  events: Observable<Event[]>;
  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.events = this.eventService.getActiveEvents();
  }

}
